import React, { useState, useContext, useEffect, useCallback } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import MenuContext from '../context/menuContext';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Dialog from '../components/dialog';
import Cookies from 'js-cookie';
import { checkLoginStatus, web_02_get_menu, web_113_get_lang, web_121_get_page_lang } from '../api/api';
import { ToastContext } from '../context/ToastContext';
import LoadingContext from '../context/loadingContext';
import useSystemText from '../helper/useSystemText';

const Menu = () => {

  const {menuData, showMenu, setShowMenu,showMenuList,setShowMenuList } = useContext(MenuContext)
  const [username] = useState(Cookies.get('username'))
  const [uuid] = useState(Cookies.get('cookie_uuid'))
  // const [isCheckingLoginStatus, setIsChecking] = useState(false);
  const navigate =  useNavigate()
  const location = useLocation() 
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [timeLeft, setTimeLeft] = useState(30 * 60 * 1000); // 初始化為 10 分鐘的毫秒數
  const [intervalId, setIntervalId] = useState(null); // 紀錄登出計時器ID\
  const [keySequence, setKeySequence] = useState("");
  const [isHoverMenu, setIsHoverMenu] = useState(false);

  const sysText10000 = useSystemText(10000);
  const sysText10001 = useSystemText(10001);
  const sysText10039 = useSystemText(10039);
  
  // console.log("showMenuList:", showMenuList);
  // console.log("menuData: ", menuData)
  // console.log("location: ", location)


  //登出
  const logout = () => {
    Object.keys(Cookies.get()).forEach(cookie => Cookies.remove(cookie));          
    window.location.href = '/login';
  } 

  useEffect(() => {
    const controller = new AbortController();

    const loginState = Cookies.get('login_state')
    const cookie_uuid = Cookies.get('cookie_uuid');
    const cookie_hash = Cookies.get('cookie_hash');
    
    const checkStatus = async () => {      
      
      if (!loginState) {
        
        const userData = {
          user: '',
          user_password: '',
          cookie_hash: cookie_hash || '',
          cookie_uuid: cookie_uuid || '',
        };

        const { status, statusText } = await checkLoginStatus(userData, controller);

        if (status === 200) {
          Cookies.set('login_state', 'loggedIn', { expires: 30 });
        } else {
          logout()          
          showToast('error', "" + status, `帐号验证失败，请重新登入 checkStatus` )         
        }     
          
      }
    };
    
    const checkMenu = async () => {     
      const userData = { 
        cookie_hash: cookie_hash || '',
        cookie_uuid: cookie_uuid || '',
      };
      
      const { listResponse, status, statusText } = await web_02_get_menu(userData, controller);

      console.log("status: ", status);
      if (status === 200) {
        //將API值取回context去判斷顯示          
        setShowMenuList(listResponse.result_list)

        //先判斷母路由是否匹配 判斷ID, 再判斷子路由 子ID
        menuData.forEach(row => {
          // 檢查 row.link 是否等於當前路由
          if (row.link === location.pathname) {
            // 如果 row.id 不在 listResponse 中，將用戶導向 /home
            if (!listResponse.result_list.includes(row.id) && row.id !== "0") {
              window.location.href = "/home"
            }
          }else {
            // 如果 item.items 存在，則檢查每個 subItem.link 是否等於當前路由
            if (row.items) {
              row.items.some(subItem => {
                if (subItem.link === location.pathname) {
                  // 如果 subItem.id 不在 listResponse 中，將用戶導向 /home
                  if (!listResponse.result_list.includes(parseInt(subItem.id))) {
                    window.location.href = "/home"
                  }else {
                    return true
                  }
                }
              });
            }         
          }
        });
        
        Cookies.set("mallname", listResponse.mallname, { expires: 30 })
        
      } else if (status === 401) {       
        logout()        
        showToast('error', ""+status, `${statusText} & URL:${location.pathname}` )         
      } else {       
        // logout()        
        showToast('error', ""+status, `${statusText} & URL:${location.pathname}` )         
      }         
    }   
    
    const checkCookieLoaded = () => {
      // 检查cookie是否加载完成的逻辑
      if (document.cookie) {
        // cookie加载完成后执行的代码
        console.log('Cookie加载完成');
        // 调用你想要运行的函数
       checkStatus().then(()=>{        
          checkMenu()
        }).finally(() => {
          setIsLoading(false)
        })             
      } else {
        // 如果cookie还未加载完成，则继续等待
        setTimeout(checkCookieLoaded, 100);
      }
    };

    //讓Cookie不為空 checkCookieLoaded判斷是否載入Cookie
    Cookies.set("CookieLoaded", "ok", { expires: 30 })
    if(!isLoading) {
      setIsLoading(true);
      checkCookieLoaded()      
    }   

    return () => {      
      controller.abort();     
    }
    
  }, [location]);


  // 判斷sessionStorage，取說明文字
  useEffect(() => {
    const data = sessionStorage.getItem("helpText")
    // console.log("data: ",data);
    if(!data) {

      const getHelpText = async() => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash")               
        }
                
        try {
          const { listResponse, status, statusText} = await web_113_get_lang(filterData);          
          console.log("listResponse: ", listResponse)          
    
          if (status === 200) {                     
            setIsRefresh(prev => !prev)
            sessionStorage.setItem("helpText", JSON.stringify(listResponse.filter(item => item.id < 10000)))
            sessionStorage.setItem("systemText", JSON.stringify(listResponse.filter(item => item.id >= 10000)))             
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      getHelpText()      
      
    }
  }, [location])
  
  //跳轉頁面關閉menu
  useEffect(() => {
    setShowMenu(false)
    let currentMenu = ""
    menuData.map(row => {
      if(location.pathname.includes(row.link))
      {
        currentMenu = row.label
      }
    })
    document.title = `后台系统 ｜ ${currentMenu}`
  }, [location])

  // //30秒刷新一次頁面資料
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsRefresh(prev => !prev)     
  //   },600000)

  //   return () => {
  //     clearTimeout(timer);
  //     console.log("10分鐘刷新")
  //   };    
    
  // }, [isRefresh])
  
  useEffect(() => {    
    if(timeLeft > 0) {
      clearInterval(intervalId);
      const id = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1000);
      }, 1000);
      setIntervalId(id);  
    }else {
      logout()      
    }    

    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

  useEffect(() => {    
   setTimeLeft(30 * 60 * 1000)
  }, [location.pathname]);

  const minutes = Math.floor(timeLeft / 60000);
  const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

  //系統語系設定
  useEffect(() => {
    const data = sessionStorage.getItem("langHTML")
    // console.log("data: ",data);
    if(!data) {

      const getLangHTML = async() => {
        setIsLoading(true)       
                
        try {
          const { listResponse, status, statusText} = await web_121_get_page_lang();          
          console.log("listResponse: ", listResponse)          
    
          if (status === 200) {                     
            setIsRefresh(prev => !prev)
            sessionStorage.setItem("langHTML", listResponse)
            document.documentElement.lang = listResponse                         
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      getLangHTML()         
    }else if(data != document.documentElement.lang) {
      document.documentElement.lang = data
    }


  }, [location])

  //密技
  useEffect(() => {
    const keydownHandler = ({ key }) => {
      setKeySequence((prevKeys) => prevKeys + key);
    };
  
    window.addEventListener("keydown", keydownHandler);
  
    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, []);

  useEffect(() => {
    if(keySequence.length > 20) {
      setKeySequence("")
    }
    if (keySequence.includes("ken.net")) {
      clearInterval(intervalId);
      setTimeLeft(Infinity);
    }
    
  }, [keySequence]);

  return (
  
    <>
      {isHoverMenu ?        
        <div className='menuWrap h-full w-[300px] bg-[#242526] p-2 max-2xl:hidden overflow-y-scroll' onMouseEnter={() => setIsHoverMenu(true)} onMouseLeave={() => setIsHoverMenu(false)}>   
          
          <div className='text-sm'>          
            <div className='m-4 flex flex-row'>
              <p>{`${sysText10000}：`}</p>
              <span className='mx-2 px-2 py-0.5 bg-red-950 border border-red-900'>{username}</span>
            </div>
            <div className='m-4 flex flex-row'>
              <p>{`${sysText10001}：`}</p>
              <span className='mx-2 px-2 py-0.5 bg-blue-950 border border-blue-800 '>{uuid}</span>
            </div>
            <div className='m-4 flex flex-row'>
              <p>{`${sysText10039}`}：</p>
              <span className={`mx-2 px-2 py-0.5 ${minutes > 10 ? 'text-green-500' : (minutes > 4 ? 'text-yellow-500' : 'text-red-500')}`}>
                {`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`}
              </span>
            </div>
          
          </div>       
          <div className="card flex justify-content-center">
            <PanelMenu model={menuData} className="w-full md:w-25rem" />
          </div>      
        </div>     
        :
        <div className='menuWrap h-full w-[10px] bg-[#242526] max-2xl:hidden overflow-hidden shadow-lg shadow-yellow-500' onMouseEnter={() => setIsHoverMenu(true)}></div>
        
      }

      {
        showMenu && 
        <Dialog className='min-2xl:hidden'>
          <div className='menuWrap mt-4 min-2xl:hidden min-w-[300px] w-[300px] bg-[#242526] p-2 overflow-y-auto '>
            <div className='text-sm'>          
              <div className='m-4 flex flex-row'>
                <p>{`${sysText10000}：`}</p>
                <span className='mx-2 px-2 py-0.5 bg-red-950 border border-red-900'>{username}</span>
              </div>
              <div className='m-4 flex flex-row'>
                <p>{`${sysText10001}：`}</p>
                <span className='mx-2 px-2 py-0.5 bg-blue-950 border border-blue-800 '>{uuid}</span>
              </div>
              <div className='m-4 flex flex-row'>
                <p>{`${sysText10039}：`}</p>
                <span className={`mx-2 px-2 py-0.5 ${minutes > 10 ? 'text-green-500' : (minutes > 4 ? 'text-yellow-500' : 'text-red-500')}`}>
                  {`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`}
                </span>
              </div>         
            </div>           
            <div className="card flex justify-content-center">
              <PanelMenu model={menuData} className="w-full md:w-25rem" />
            </div>      
          </div>          
        </Dialog>          
          
      }
    </>
  );
};

export default Menu;