import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { imgOnError } from '../../../helper/imgOnError';
import useCopy from '../../../helper/useCopy';
import useJson from '../../../helper/useJson';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ToastContext from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { insert_conversation, show_conversation } from '../../../api/api';
import { useLocation } from 'react-router-dom';

const PurchaseRecordDetail = (props) => {

  const { detailData, setShowDetail } = props
  const {copyHandler} = useCopy()
  const {jsonToObject} = useJson()
  const helpTextPicture = useHelpText(0)
  const {setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const [showConversation, setShowConversation] = useState(false)  
  const [contentInput, setContentInput] = useState("")
  const [conversationData, setConversationData] = useState([])
  const location = useLocation()

  const sysText10009 = useSystemText(10009)

  const sysText306001 = useSystemText(306001)
  const sysText306004 = useSystemText(306004)
  const sysText306018 = useSystemText(306018)
  const sysText306019 = useSystemText(306019)
  const sysText306006 = useSystemText(306006)
  const sysText306011 = useSystemText(306011)  
  
  const sysText201022 = useSystemText(201022)
  const sysText201023 = useSystemText(201023)
  const sysText201024 = useSystemText(201024)


  // let payeeName, payeeBank, payeeCardnumber

  // if(detailData.收款人資訊) {
  //   const payeeData = jsonToObject(detailData.收款人資訊)
  //   payeeName = payeeData?.姓名
  //   payeeBank = payeeData?.银行名称
  //   payeeCardnumber = payeeData?.卡号
  // }    

  const onSubmit = async() => {

    let filterData = {}

    if(contentInput.slice(0, 3) === "/s ") {
      filterData = {       
        order_id: detailData.採購單編號,
        sender: "s",
        content: contentInput.slice(3, contentInput.length)
      };
    }else {
      filterData = {       
        order_id: detailData.採購單編號,
        sender: "c",
        content: contentInput
      };
    }    

    try {
      const { listResponse, status, statusText } = await insert_conversation(filterData);          
      console.log("listResponse: ", listResponse)          

      if (status === 200) { 
        setIsRefresh(prev => !prev)
        showToast("success", "成功送出讯息")        
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )        
      }
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }  
  }

  const imgDetail = (item) => {
    return (
    <>
      <HelpIcon tooltipText={helpTextPicture}></HelpIcon>
      <a href={`https://water168.shop/pic/${item.img}`} target='_blank' rel='noopener noreferrer'>     
        <img src={`https://water168.shop/pic/${item.img}`} className='max-w-[100px] max-h-[100px]' onError={({currentTarget}) => imgOnError(currentTarget)}></img>
      </a>
    </>
    )
  }

  useEffect(() => {      
    setIsLoading(true)
    const filterData = {       
      order_id: detailData.採購單編號
    };

    const fetchData = async() => {
            
      try {
        const { listResponse, status, statusText } = await show_conversation(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) { 
          setConversationData(listResponse.conversation)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)    
  }, [detailData, isRefresh]);

  return (
    <>  
      <Dialog>
        <div className='mx-auto w-[70vw] min-w-[350px] max-h-[600px] max-md:w-full bg-customColor-bgGray rounded-xl overflow-y-scroll'>
          
            <div className='flex items-center p-6 border-b border-gray-700'>
              <h2 className='text-lg'>{sysText306018}</h2><span className='ml-auto mr-2'><HelpIcon tooltipText={helpTextPicture}/></span>
            </div>

            <div className='p-6 text-sm space-y-4'>

              {detailData.收款人資訊 ?
                <div className='flex flex-wrap'>
              
                <ul className='flex flex-col w-[50%] max-2xl:w-[100%]'>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>{sysText306004}</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.採購單編號}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>收款資訊</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.收款人資訊}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>寄件人</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.收款人資訊}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>收件人</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.收件人}</p>
                  </li>
                  <li className=' my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>收件地址</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.收件人地址}</p>
                  </li>                          
                </ul>              
              
                <ul className='flex flex-col w-[50%] max-2xl:w-[100%]'>
                  <li className='my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>{sysText306001}</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.採購單時間}</p>
                  </li>
                  <li className='my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>寄件地址</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.寄件人地址}</p>
                  </li>
                  <li className='my-2'>
                    <label className='bg-customColor-bgDark text-sm text-blue-300'>快递单号</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.快遞單號}</p>
                  </li>
                                
                </ul>
              
            </div>
            :
            null
              }

              <div className='flex flex-col mb-6 space-y-4'>
                <div className=''>
                  <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>{sysText306019}</span>
                </div>
                <div className='flex flex-row'>
                  <div className='p-2'>
                    {detailData.圖片1 ?
                      <a href={`${detailData.圖片1}`} target="_blank" rel="noreferrer">
                        <img className='max-w-[180px] max-h-[180px]' onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${detailData.圖片1}`} />
                      </a>
                      :
                      <div className='flex justify-center items-center w-[180px] h-[180px] text-gray-500 text-sm bg-customColor-bgLightGray'>FAILED</div>
                    }
                  </div>
                  <div className='p-2'>
                    {detailData.圖片2 ?
                      <a href={`${detailData.圖片2}`} target="_blank" rel="noreferrer">
                        <img className='max-w-[180px] max-h-[180px]' onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${detailData.圖片2}`} />
                      </a>
                      :
                      <div className='flex justify-center items-center w-[180px] h-[180px] text-gray-500 text-sm bg-customColor-bgLightGray'>FAILED</div>
                    }
                  </div>
                </div>
              </div>               

              <div className='ml-auto my-4 w-[25%] max-2xl:w-full'>
                <ul>
                  <li className='flex justify-between m-2'><p>{sysText201022}</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>¥ {detailData.採購單金額}</p></li>
                  <li className='flex justify-between m-2'><p>{sysText201023}</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{""}</p></li>
                  <li className='flex justify-between m-2'><p>运费</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer text-red-500" onClick={(e) => copyHandler(e.target.innerText)}>¥ 0</p></li>
                  <li className='flex justify-between m-2'><p>{sysText201024}</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>¥ {detailData.採購單金額}</p></li>
                </ul>
              </div>

              {showConversation && 
                <div className='flex flex-col w-full h-full'>
                  <div className='p-4'>
                    <div className='w-full bg-gray-300 text-black rounded-xl space-y-4'>
                      <div className='max-h-[70vh] overflow-y-scroll p-4 space-y-4'>
                        {conversationData?.map((item) => (
                          item.sender == "s" ? 
                          <div className='flex flex-row w-[50%] space-x-1'>
                            <div className='flex justify-center items-center'>
                              <div className='rounded-full overflow-hidden'>
                                <img className='min-w-[24px] max-w-[24px]' src="/images/conversationImg.jpg"></img>
                              </div>
                            </div>
                            <div className='contentWrap bg-white rounded-xl p-2 text-sm'>
                              {item.content}
                            </div>
                          </div>
                          : item.sender == "c" ?
                          <div className='flex flex-row justify-end w-[50%] ml-auto space-x-1'>  
                              <div className='contentWrap bg-white rounded-xl p-2 text-sm'>
                                {item.content}
                              </div>
                              <div className='flex justify-center items-center w-10 h-8 '>
                                <div className='rounded-full overflow-hidden'>
                                  <img className='min-w-[24px] max-w-[24px]' src="/images/conversationImg.jpg"></img>
                                </div>
                              </div>
                          </div>
                          : null
                        ))}
                      </div>

                      <div className='contentInput flex flex-row items-center rounded-xl'>
                        <textarea className='contentInput w-full h-full' value={contentInput} onChange={(e) => setContentInput(e.target.value)} placeholder='输入讯息'></textarea>
                        <div className='justify-end'>
                          <Button className='w-16 whitespace-nowrap' severity='info' label='送出' onClick={onSubmit}></Button>
                        </div>
                      </div>
                      
                    </div>              
                  </div>
                </div>    
              }     
            </div>          

            <div className='flex w-full justify-around items-center border-t border-gray-700 py-2'>
              <Button className='flex justify-center w-36' label='查看对话' onClick={() => { setShowConversation(prev => !prev) }} size="small" severity="info" raised></Button>
              <Button className='flex justify-center w-36' label='关闭' onClick={() => { setShowDetail(false) }} size="small" severity="secondary" raised></Button>
            </div>         

        </div>
      </Dialog>

    </>
  );
};

export default PurchaseRecordDetail;