import React, { useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import { web_48_withdrawal } from '../../../api/api';
import LoadingContext from '../../../context/loadingContext';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import useSystemText from '../../../helper/useSystemText';

const ShareRecordWithdraw = (props) => {

  const {setShowWithdraw, withdrawData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText501018 = useSystemText(501018)
  const sysText501015 = useSystemText(501015)
  const sysText501016 = useSystemText(501016)
  const sysText501017 = useSystemText(501017)
  const sysText501020 = useSystemText(501020)
  const sysText501021 = useSystemText(501021)
  const sysText501022 = useSystemText(501022)


  console.log("withdrawData", withdrawData);

  const formik = useFormik({
    initialValues: {
        amount: 0,
        staffname: '',
        bankname: '',
        cardnumber: '',
        remark: ''
    },
    validate: (data) => {
        let errors = {};       
        const numberRegExp10 = /^\d{1,10}(\.\d{1,2})?$/
        const numberRegExp20 = /^\d{1,20}(\.\d{1,2})?$/

        if (!data.amount) {								
          errors.amount = '必填';
        }else if(data.amount > withdrawData) {
          errors.amount = "提领金额不可大于可提领金额"
        }else if(!numberRegExp10.test(data.amount)) {
          errors.amount = "必須為數字"
        }

        if (!data.staffname) {								
          errors.staffname = '必填';
        }else if(data.staffname.length > 30) {
          errors.staffname = '不可超過30字';
        }
       
        if (!data.bankname) {								
          errors.bankname = '必填';
        }else if(data.bankname.length > 30) {
          errors.bankname = '不可超過30字';
        }

        if (!data.cardnumber) {								
          errors.cardnumber = '必填';
        }else if(!numberRegExp20.test(data.cardnumber)) {
          errors.cardnumber = '必須為數字';
        }      

        return errors;
    },
    onSubmit: async(data) => { 
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        withdrawal_amount: data.amount,
        bankname: data.bankname,
        staffname: data.staffname,
        staffcardnumber: data.cardnumber,
        remark: data.remark        
      };    
      
      try {
       
        const { listResponse, status, statusText } = await web_48_withdrawal(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowWithdraw(false)              
          showToast("success", "" + status, `成功提领 金额: ${filterData.withdrawal_amount}，卡号:${filterData.staffcardnumber}` )         
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }  
      
      formik.resetForm();
    }
    
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }  

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <form className='flex flex-col' onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
            <div className='border-b border-gray-700 p-4 text-lg'>{sysText501020}</div>
            <div className='p-4'>
              <p>{sysText501021}： {withdrawData}</p>
            </div>            
            <div className='space-y-1 text-sm my-2 p-4 overflow-y-scroll'>  
              <div>
                <label htmlFor='amount'>{sysText501018}</label>
                <input type="text" name="amount" onChange={formik.handleChange} className='w-full' placeholder={sysText501018} autoComplete='off'/>
                {getFormErrorMessage('amount')}
              </div>

              <div>
                <label htmlFor='staffname'>{sysText501015}</label>
                <input name="staffname" onChange={formik.handleChange} className='w-full' placeholder={sysText501015} type="text" autoComplete='off'/>
                {getFormErrorMessage('staffname')}
              </div>

              <div>
                <label htmlFor='bankname'>{sysText501016}</label>
                <input name="bankname" onChange={formik.handleChange} className='w-full' placeholder={sysText501016} type="text" autoComplete='off'/>
                {getFormErrorMessage('bankname')}
              </div>

              <div>
                <label htmlFor='cardnumber'>{sysText501017}</label>
                <input name="cardnumber" onChange={formik.handleChange} className='w-full' placeholder={sysText501017} type="text" autoComplete='off'/>
                {getFormErrorMessage('cardnumber')}
              </div>

              <div>
                <label htmlFor='remark'>{sysText501022}</label>
                <input name="remark" onChange={formik.handleChange} className='w-full' placeholder={sysText501022} type="text" autoComplete='off'/>             
                {getFormErrorMessage('remark')}
              </div>              
            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowWithdraw(false)}></Button>
              <Button style={{width: "120px"}} type="submit" label={sysText10002} severity='info' raised></Button>
            </div>
          </form>
         
        </div>
      </Dialog>
    </>
  );
};

export default ShareRecordWithdraw;