import React, { useState, useContext, useRef, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import ImgInput from '../../UI/imgInput';
import Cookies from 'js-cookie';
import { uploadfile, web_04_ncc_complete_pur_sub_order, web_51_get_functionToggle } from '../../../api/api';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import useSystemText from '../../../helper/useSystemText';

const PurchaseRecordNccComplete = (props) => {

  const {setShowComplete, completeData} = props
  const [fileImg1, setFileImg1] = useState(completeData.圖片1)
  const [fileImg2, setFileImg2] = useState(completeData.圖片2)
  const [imgFunction, setImgFunction] = useState(true)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText306021 = useSystemText(306021)
  const sysText306022 = useSystemText(306022)
  const sysText306023 = useSystemText(306023)
  const sysText306047 = useSystemText(306047)

  console.log("completeData: ", completeData);
  console.log("fileImg1: ", fileImg1);
  console.log("fileImg2: ", fileImg2);

  const confirmHandler = async() => {
    if(imgFunction) {
      if(!fileImg1 && !fileImg2) {
        showToast("error", "请先上传图片")
        return
      }
    }

    const uploadHandler = async(fileImg) => {
      setIsLoading(true)
      const filterData = {
        fileImg: fileImg          
      }            
        
      try {
        const { listResponse, status, statusText } = await uploadfile(filterData);        
        console.log(filterData);
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {
          // 將API返回的URL傳回給父組件
          return listResponse.url                      
                    
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          showToast("error", "上傳圖片失敗")
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
        // setFile(null);
        // setPreview(null);
        // event.target.value = null;
      }
    }
    //等待圖片上傳 並回傳URL
    let img_url1 = null
    let img_url2 = null

    if(fileImg1 && fileImg1 !== completeData.圖片1) {
      img_url1 = await uploadHandler(fileImg1)
    }else {
      img_url1 = completeData.圖片1
    }

    if(fileImg2 && fileImg2 !== completeData.圖片2) {
      img_url2 = await uploadHandler(fileImg2)
    }else {
      img_url2 = completeData.圖片2
    }   
    
    const confirmPurOrder = async() => {
      setIsLoading(true)
      let filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        ordernumber: completeData.採購單編號 || completeData.子單單號        
      }

      if (img_url1) {
        filterData.img1 = img_url1
      }

      if (img_url2) {
        filterData.img2 = img_url2
      }
  
      try {
        
        const { listResponse, status, statusText } = await web_04_ncc_complete_pur_sub_order(filterData);        
       
        // console.log("listResponse: ", listResponse)
  
        if (status === 200) {           
          setShowComplete(false)
          showToast("success", "" + status, `${sysText306047}:\n ${filterData.ordernumber}` )
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
        setIsLoading(false)
      } catch (error) {        
        console.error(location.pathname, error);            
      }
    } 

    await confirmPurOrder()
  }
    
  
  useEffect(() => {

    const fetchToggle = async() => {
      setIsLoading(true)
      
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),       
      }
  
      try {
        const { listResponse, status, statusText } = await web_51_get_functionToggle(filterData);        
        
        // console.log("listResponse: ", listResponse)
  
        if (status === 200) {          
          const row_id1 = listResponse.find(item => item.id === 1)
          if(row_id1.值 === 1) {
            setImgFunction(true)
          }else if (row_id1.值 === 0) {
            setImgFunction(false)
          }else {
            return ""
          }
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      } 
    }
    fetchToggle()
    
  }, [])

  useEffect(() => {
    if(completeData.圖片1 === "無須上傳圖片" && completeData.圖片2 === "無須上傳圖片") {
      setFileImg1("")
      setFileImg2("")
    }   
    
  }, [completeData])

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-h-[800px] max-md:max-h-[500px] overflow-y-scroll bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText306021}</div>
            <div className='p-6 flex flex-col'>
              {imgFunction &&
                <>
                  <div className='mb-6'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>{sysText306022}</span>
                  </div>
                  <ImgInput fileImg1={fileImg1} setFileImg1={setFileImg1} fileImg2={fileImg2} setFileImg2={setFileImg2} defaultImg1={completeData.圖片1} defaultImg2={completeData.圖片2}/>
                </>              
              }
              
              <div>
                <p className='text-sm'>{sysText306023}</p>
                <p className='text-yellow-300'>{completeData.採購單編號 || completeData.子單單號}</p>   
              </div>                      
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowComplete(false)}></Button>
              <Button style={{width: '120px'}} label={sysText10002} severity='info' raised onClick={confirmHandler} ></Button>              
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseRecordNccComplete;