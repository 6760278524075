
import {  
  Routes,
  Route,
     
} from "react-router-dom";
import { MenuProvider } from "./context/menuContext";
import Navbar from "./layouts/navbar";
import Login from "./pages/login";
import Main from "./pages/main"
import Record from "./pages/orderManage/record";
import PurchaseRecord from "./pages/purchaseManage/purchaseRecord";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import "./css/index.css";
import Systeminformation from "./pages/accountManage/systeminformation";
import UserManage from "./pages/accountManage/userManage";
import ShareRecord from "./pages/share/shareRecord";
// import StartUp from "./pages/wechatHelper/startUp";
import CardSetting from "./pages/other/cardSetting";
import CreditManage from "./pages/other/creditManage";

import { ToastProvider } from './context/ToastContext';
import { LoadingProvider } from "./context/loadingContext";
import Loading from "./components/loading";
// import StatusManage from "./pages/wechatHelper/statusManage";
import CardCheck from "./pages/other/cardCheck";
import PurchaseMate from "./pages/purchaseManage/purchaseMate";
import RecordAdd from "./pages/orderManage/recordAdd";
import FlowRecord from "./pages/orderManage/flowRecord";
import PurchaseAdd from "./pages/purchaseManage/purchaseAdd";
// import BankKeyword from "./pages/other/bankKeyword";
import ShareEdit from "./pages/share/shareEdit";
import AddFlowRecord from "./pages/orderManage/addFlowRecord";
import ReportDownload from "./pages/other/reportDownload";
import Home from "./pages/home";
import PurchaseReversal from "./pages/purchaseManage/purchaseReversal";
import ClearCache from "./pages/clearCache";
import InformationBoard from "./pages/other/informationBoard";
import MenuSetting from "./pages/other/menuSetting";
import GatewaySetting from "./pages/accountManage/gatewaySetting";
import MerchantnoList from "./pages/merchant/merchantList";
import RecordNcc from "./pages/orderManage/recordNcc";
import RecordNccAdd from "./pages/orderManage/recordNccAdd";
import CardSettingNcc from "./pages/other/cardSettingNcc";
import CardCheckNcc from "./pages/other/cardCheckNcc";
import CardSettingCc from "./pages/other/cardSettingCc";
import CardCheckCc from "./pages/other/cardCheckCc";
import PurchaseGrab from "./pages/purchaseManage/purchaseGrab";
import PurchaseRecordNcc from "./pages/purchaseManage/purchaseRecordNcc";
import PurchaseMateNcc from "./pages/purchaseManage/purchaseMateNcc";
import PurchaseAddNcc from "./pages/purchaseManage/purchaseAddNcc";
import ThirdParty from "./pages/other/thirdParty";
import OrderNotify from "./pages/orderManage/orderNotify";
import PurchaseNotify from "./pages/purchaseManage/purchaseNotify";
import RiskControl from "./pages/other/riskControl";
import FreezeOrder from "./pages/orderManage/freezeOrder";
import FreezeOrderNcc from "./pages/orderManage/freezeOrderNcc";
import DailyResult from "./pages/dashboard/dailyResult";
import MerchantMonitoring from "./pages/dashboard/merchantMonitoring";
import AgencyTransaction from "./pages/dashboard/agencyTransaction";
import ThirdPartyResult from "./pages/dashboard/thirdPartyResult";
import SystemTextSetting from "./pages/other/systemTextSetting";
import ShareEditGateway from "./pages/share/shareEditGateway";
import PurchaseAddThirdParty from "./pages/purchaseManage/purchaseAddThirdParty";
import PurchaseMateThirdParty from "./pages/purchaseManage/purchaseMateThirdParty";
import { SystemTextProvider } from "./context/systemTextContext";

function App() { 

  return (
    <>
      <LoadingProvider>  
        <ToastProvider>
          <SystemTextProvider>          
            <MenuProvider>
              <Loading></Loading>           
                <div className="h-[100dvh]">
                  <Navbar></Navbar>                
                    <div className="contentWrap h-[calc(100%-5rem)] max-2xl:h-[calc(100%-3rem)]">                   
                      <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={<Main />}>
                          <Route path="" element={<Home/>}></Route>                                               
                          <Route path="home" element={<Home/>}></Route>
                          <Route path="orderManage">
                            {/* <Route path="record" element={<Record/>}/> */}
                            <Route path="recordAdd" element={<RecordAdd/>}></Route>
                            <Route path="flowRecord" element={<FlowRecord/>}></Route>
                            <Route path="addFlowRecord" element={<AddFlowRecord/>}></Route>
                            <Route path="record-Ncc" element={<RecordNcc/>}></Route>
                            <Route path="recordAdd-Ncc" element={<RecordNccAdd/>}></Route>
                            <Route path="orderNotify" element={<OrderNotify/>}/>
                            {/* <Route path="freezeOrder" element={<FreezeOrder/>} /> */}
                            <Route path="freezeOrder-Ncc" element={<FreezeOrderNcc/>} />

                          </Route> 

                          
                          <Route path="purchaseManage">
                            {/* <Route path="purchaseRecord" element={<PurchaseRecord/>} /> */}
                            <Route path="purchaseRecord-Ncc" element={<PurchaseRecordNcc/>} />
                            <Route path="purchaseAdd-thirdParty" element={<PurchaseAddThirdParty/>} />
                            {/* <Route path="purchaseMate" element={<PurchaseMate/>} /> */}
                            <Route path="purchaseMate-Ncc" element={<PurchaseMateNcc/>} />
                            <Route path="purchaseMate-thirdParty" element={<PurchaseMateThirdParty/>} />
                            {/* <Route path="purchaseAdd" element={<PurchaseAdd/>} /> */}
                            <Route path="purchaseAdd-Ncc" element={<PurchaseAddNcc/>} />
                            <Route path="PurchaseReversal" element={<PurchaseReversal/>}/>
                            <Route path="PurchaseGrab" element={<PurchaseGrab/>}/>
                            <Route path="PurchaseNotify" element={<PurchaseNotify/>}/>
                            
                          </Route>

                          <Route path="accountManage">
                            <Route path="systemInformation" element={<Systeminformation/>} />
                            <Route path="userManage" element={<UserManage/>} />
                            <Route path="gatewaySetting" element={<GatewaySetting/>} />
                          </Route>

                          <Route path="share">
                            <Route path="shareRecord" element={<ShareRecord/>} />
                            <Route path="shareEdit" element={<ShareEdit/>} />
                            <Route path="shareEditGateway" element={<ShareEditGateway/>} />
                          </Route>

                          <Route path="merchantno">
                            <Route path="merchantnoList" element={<MerchantnoList/>} />                              
                          </Route>

                          {/* <Route path="wechatHelper">
                            <Route path="startUp" element={<StartUp/>} />
                            <Route path="statusManage" element={<StatusManage/>} />
                          </Route> */}

                          <Route path="other">
                            {/* <Route path="cardSetting" element={<CardSetting/>} /> */}
                            <Route path="cardSetting-cc" element={<CardSettingCc/>} />
                            <Route path="cardSetting-Ncc" element={<CardSettingNcc/>} />
                            {/* <Route path="cardCheck" element={<CardCheck/>} /> */}
                            <Route path="cardCheck-Cc" element={<CardCheckCc/>} />
                            <Route path="cardCheck-ncc" element={<CardCheckNcc/>} />                          
                            <Route path="creditManage" element={<CreditManage/>} />
                            {/* <Route path="bankKeyword" element={<BankKeyword/>} /> */}
                            <Route path="reportDownload" element={<ReportDownload/>}/>
                            <Route path="informationBoard" element={<InformationBoard/>}/>
                            <Route path="menuSetting" element={<MenuSetting/>}/>
                            <Route path="thirdParty" element={<ThirdParty/>}/>
                            <Route path="riskControl" element={<RiskControl/>} />
                            <Route path="SystemTextSetting" element={<SystemTextSetting/>}/>                              

                          </Route>

                          <Route path="dashboard">
                            <Route path="dailyResult" element={<DailyResult/>} />
                            <Route path="merchantMonitoring" element={<MerchantMonitoring/>} />
                            <Route path="agencyTransaction" element={<AgencyTransaction/>}/>
                            <Route path="thirdPartyResult" element={<ThirdPartyResult/>}/>
                          </Route>

                          <Route path="clearCache" element={<ClearCache/>}></Route>                                  

                        </Route>                        
                        <Route path="/*" element={<div className="flex justify-center items-center w-full h-full ">404 Not Found...</div>}></Route>
                      </Routes>                 
                    </div>  
                                  
                </div>
                            
              
            </MenuProvider>   
          </SystemTextProvider>
        </ToastProvider>   
      </LoadingProvider>  
    </>
    
  );
}

export default App;
