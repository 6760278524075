import React, { useEffect, useContext, useState } from 'react';
import Dialog from '../../dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'; 
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { web_27_ncc_create_pur_sub_order, web_95_get_3pset_def } from '../../../api/api';
import useSystemText from '../../../helper/useSystemText';
import { ErrorMessage, useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';

const PurchaseMateThirdPartyForce = (props) => {

  const {setShowForce, selectOrder, selectCard} = props
  const location = useLocation()
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [orderAmount, setOrderAmount] = useState(selectOrder.金額)
  const [totalAmount, setTotalAmount] = useState(0)
  const [balance, setBalance] = useState({}) //物件 key 為卡號 value 為金額
  const [info, setInfo] = useState("")
  const [defOption, setDefOption] = useState([])

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText309001 = useSystemText(309001)
  const sysText309003 = useSystemText(309003)
  const sysText309010 = useSystemText(309010)
  const sysText309011 = useSystemText(309011)
  const sysText309019 = useSystemText(309019)
  const sysText309016 = useSystemText(309016)
  const sysText309027 = useSystemText(309027)
  const sysText309028 = useSystemText(309028)  
  const sysText305034 = useSystemText(305034)  

  console.log("balance: ", balance);  

  const formik = useFormik({
    initialValues: {
        mateData: '',
        def3pname: '',
        verificationCode: '',
    },
    validate: (data) => {
        let errors = {};
        const regExp = /^\d+(\.\d{1,2})?$/

        console.log(Object.entries(data.mateData));

        Object.values(data.mateData).map((value) => {
          console.log(value);
          if(!value) {
            errors.mateData = "必填"
          }else if(!regExp.test(value)){
            errors.mateData = "必須為數字且小數兩位"
          }
        })       

        if(!data.def3pname) {
          errors.def3pname = "必填"
        }

        return errors;
    },
    onSubmit: async(data) => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid") || "",
        cookie_hash: Cookies.get("cookie_hash") || "",
        crepso_pur_confirmpeople: Cookies.get("cookie_uuid") || "",
        crepso_pur_merchantno: selectOrder.商戶號,
        crepso_pur_rawordernumber: selectOrder.單號,
        crepso_pur_productamount: selectOrder.金額,    
        crepso_pur_payee_info: selectOrder.收款人姓名,      
        crepso_carddata: data.mateData,
        verification_code: data.verificationCode,
        def3pname: data.def3pname
      }
      
      try {
        const {listResponse, status, statusText} = await web_27_ncc_create_pur_sub_order(filterData)
        console.log("listResponse: ", listResponse);
  
        if (status === 200) {          
          setIsRefresh(prev => !prev)
          showToast("success", "" + status, `${sysText305034}` )
          setShowForce(false)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }       
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }    
    }
  });

const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
};

  const handleInputAmountChange = (item, amount) => {
    // 複製現有的付款金額物件，然後更新特定卡號的付款金額
    const value = {
      ...formik.values.mateData,
      [item.id]: amount,
    };    
    formik.setFieldValue("mateData", value)
   
  };

  const InfoColumn = (rowData) => {
    return <Button type='button' label={sysText309027} severity='info' text raised onClick={(e) => { 
      try{
        const data = JSON.parse(rowData.資訊)
        setInfo(data)
      }catch (e) {
        setInfo(null)
        showToast("error", "资料格式错误无法显示")
      }
    }}></Button>
  }

  const InfoDialog = ({ info, setInfo }) => {
    if (info === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setInfo(null)}
        header={sysText309010}
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText309010}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {Object.keys(info).map((key) => 
              <p key={key}>{`${key}: ${info[key]}`}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button type='button' style={{ width: "120px" }} label='OK' severity='info' raised onClick={(e) => {setInfo(null)}}></Button>
          </div>
        </div>
      </Dialog>
    );
  }; 
  const InputColumn = (item) => {
    return (
    <div className='flex flex-col'>      
      <input type="text" name='mateData' value={formik.values.mateData[item.id] ?? 0} onChange={(e) => handleInputAmountChange(item, e.target.value)} className='w-[90%]' autoComplete='off' />
      {getFormErrorMessage("mateData")}
    </div>
    )
  }

  const BalanceColumn = (item) => {
    if(formik.values.mateData[item.id]) {
      const checkAmount =  balance[item.id] - parseFloat(formik.values.mateData[item.id])
      return checkAmount >= 0 ? <div>{checkAmount}</div> : <div className='text-red-500'>{checkAmount}</div>
    }else {
      return balance[item.id]
    }    
  }

  const creatSubOrderDisable = () => {
    // const findBalance = Object.values(balance).filter(value => value < 0)
    // console.log("findBalance: ",findBalance);

    // const balanceLowZeroList = selectCard.filter(row => (balance[row.id] - formik.values.mateData[row.id]) < 0)
    // console.log("balanceLowZeroList: ", balanceLowZeroList);
    // if (balanceLowZeroList.length > 0 || totalAmount !== selectOrder.金額) {
    //   return true
    // }else {
    //   return false
    // }

    if (totalAmount !== selectOrder.金額) {
      return true
    }else {
      return false
    }
  }
  
  //加總所有input金額
  useEffect(() => {      
    let amount = 0
    Object.values(formik.values.mateData).map((item => {
      amount += parseFloat(item)
    }))    
    
    setTotalAmount(amount)   
  }, [formik.values.mateData]);

  useEffect(() => {
    if(totalAmount) {
      setOrderAmount(Math.round((selectOrder.金額 - parseFloat(totalAmount)) * 100, 2) / 100)
    }else {
      setOrderAmount(selectOrder.金額)
    }
  }, [totalAmount])

  //將所有卡的餘額將存到balance{id:值}
  useEffect(() => {     
    let newBalanceData = {} 
    selectCard.map(row => {
      newBalanceData = {...newBalanceData, [row.id]: parseFloat(row.當前餘額)}
    })
    setBalance(newBalanceData)
  }, [selectCard]);

  useEffect(() => {     
    setIsLoading(true)
    const fetch3pDef = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid") || "",
        cookie_hash: Cookies.get("cookie_hash") || "",
        gateway_type_id: selectCard[0].通道類型      
      }
      
      try {
        const {listResponse, status, statusText} = await web_95_get_3pset_def(filterData)
        console.log("listResponse: ", listResponse);
  
        if (status === 200) {          
          // setIsRefresh(prev => !prev)
          setDefOption(listResponse)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }       
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }    
    }   
    fetch3pDef()
  
  }, []);


  return (
    <>      
      <Dialog>
        <form onSubmit={formik.handleSubmit} className='mx-auto w-[700px] bg-customColor-bgGray rounded-xl max-md:w-full '>
          
          <div className='flex flex-col'>   
            <div className='border-b border-gray-500 text-lg p-6 flex-1'> {sysText309019}</div>            
            <div className='p-6'>
              <p>{sysText309001}: {selectOrder.單號}</p>
              <p>{sysText309003}: {orderAmount >= 0 ? <span>{orderAmount}</span> : <span className='text-red-500'>{orderAmount}</span>} </p>
            </div>           

            <div className='w-full tableWrap px-6'>
              <DataTable value={selectCard} scrollable scrollHeight="300px">
                <Column style={{minWidth: "80px"}} field="資訊" header={sysText309010} body={InfoColumn}></Column>
                <Column style={{minWidth: "120px"}} field="付款金额" header={sysText309016} body={InputColumn}></Column>
                <Column style={{minWidth: "80px"}} field="當前餘額" header={sysText309011} body={BalanceColumn}></Column>                                     
              </DataTable>      
            </div>    

            <div className='p-4 space-x-2'>
              <label>三方套件</label>
              <Dropdown name="def3pname" className=' w-80 custom-dropdown' options={defOption} value={formik.values.def3pname} onChange={formik.handleChange}/>
              {getFormErrorMessage("def3pname")}            
            </div>            
            
            <div className='space-x-2 p-4'>
              <label>{sysText309028}</label>
              <input name="verificationCode" className='px-2 w-24' maxLength="8" value={formik.values.verificationCode} onChange={formik.handleChange}></input>
            </div>                         
            
          </div>
          <div className='flex flex-auto w-full justify-around items-center mt-3 py-2'>
            <Button className='flex justify-center' label={sysText10003} style={{width: "120px"}} onClick={() => {setShowForce(false)}} severity="secondary" raised></Button>
            <Button type="submit" className='flex justify-center' label={sysText10002} style={{width: "120px"}} disabled={creatSubOrderDisable()} severity="info" raised></Button>
          </div> 
        </form>
      </Dialog>       
      
      {info && <InfoDialog info={info} setInfo={setInfo}/>}
    </>
    
  );
};

export default PurchaseMateThirdPartyForce;