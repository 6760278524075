import React, { useState, useEffect, useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import ImgInput from '../../UI/imgInput';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_17_ncc_edit_nccset_gateway, web_17_ncc_show_nccset_gateway, web_52_get_all_uuid } from '../../../api/api';
import { Dropdown } from 'primereact/dropdown';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const BatchEditNcc = (props) => {

  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {setShowBatchEdit} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [uuidOption, setUuidOption] = useState([])
  const [selectUuid, setSelectUuid] = useState("")
  const [gatewayData, setGatewayData] = useState([])
  const [selectGateway, setSelectGateway] = useState({})
  const location = useLocation()
  const helpText = useHelpText(13)

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
 
  const sysText701001 = useSystemText(701001)
  const sysText701030 = useSystemText(701030)
  const sysText701040 = useSystemText(701040)
  
  const jsonToObject = (jsonData) => {
    try {
      const obj = JSON.parse(jsonData)  
      return obj    
    } catch (e) {
      return ""
    }
    
  }

  const checkboxChangeHandler = (event, nccsetId, gatewayId) => {
    
    setSelectGateway(prevState => {
      // 创建 selectGateway 的深拷贝
      const newObj = {...prevState};
  
      // 如果 checkbox 被选中
      if (event.target.checked) {
        // 如果 nccsetId 已经存在，则添加 gatewayId 到数组
        if (newObj[nccsetId]) {
          newObj[nccsetId] = [...newObj[nccsetId], gatewayId];
        } else {
          // 如果 nccsetId 不存在，则创建新数组并添加 gatewayId
          newObj[nccsetId] = [gatewayId];
        }
      } else {
        // 如果 checkbox 被取消选中，则从数组中移除 gatewayId
        newObj[nccsetId] = newObj[nccsetId].filter(item => item !== gatewayId);
      }
  
      // 返回更新后的对象作为新的状态
      return newObj;
    });
    
  };

  const confirmEdit = async() => {

    let selectGatewayJson = ""
    try {
      selectGatewayJson = JSON.stringify(selectGateway)
    }catch(e) {
      showToast("error", "格式错误")
    }
    console.log("selectGateway", selectGateway);
    console.log("selectGatewayJson: ", selectGatewayJson);

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      data: selectGatewayJson      
    }    
    
    setIsLoading(true)   
    try {
      const { listResponse, status, statusText } = await web_17_ncc_edit_nccset_gateway(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {          
        showToast("success",`${sysText701040}: \n${selectUuid}` )
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }   
    
    setShowBatchEdit(false)
  }
   
  useEffect(() => {    

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")      
    }
    
    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_52_get_all_uuid(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {          
          setUuidOption(listResponse)                  
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [isRefresh]);

  useEffect(() => {      
    // setSelectGateway({})

    const fetchGateWayData = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        select_uuid: selectUuid          
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_17_ncc_show_nccset_gateway(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                              
          setGatewayData(listResponse)
          let newObj = {}
          listResponse.map((item) => {            
            newObj[item.nccset_id] = item.已選擇            
          })
          console.log("newObj", newObj);
          setSelectGateway(newObj)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchGateWayData()    
  }, [selectUuid]);  

  console.log("uuidOption: ", uuidOption);
  console.log("selectUuid: ", selectUuid);
  console.log("selectGateway: ", selectGateway);  

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[800px] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-lg overflow-auto'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between border-b border-gray-700 px-8 py-4 text-lg'>
              <h2>{sysText701030}</h2>
              <div><HelpIcon tooltipText={helpText}/></div>
            </div>
                    
            <div className='space-y-4 text-sm my-2 px-8 py-4 h-[60vh] overflow-y-scroll'>

              <label htmlFor='selectUuid'>{sysText701001}</label>
              <div className='flex flex-row items-center space-x-4'>                  
                <Dropdown filter name="selectUuid" className='custom-dropdown' value={selectUuid} options={uuidOption} optionLabel='uuid' optionValue='uuid' onChange={(e) => setSelectUuid(e.value)} placeholder="请选择uuid"/>                
              </div>   

              <div className='flex flex-col space-y-4'>
                {gatewayData.map((data) => 
                  <div key={data.nccset_id}>
                    <div className="flex flex-row flex-wrap items-center space-x-2">
                      <h2 className='text-xl'>{data.type_name}</h2>                      
                      {Object.entries(jsonToObject(data.資訊)).map(([key, value], index) =>
                      <div key={key + value}>                        
                        <small className="text-blue-500">{key}: </small>
                        <small className="text-blue-500">{value}</small>
                      </div>
                      )}
                    </div> 
                    
                    <div className='flex flex-row flex-wrap'>
                      {Object.entries(data.所有通道).map(([key, [typeName, merchantno]]) => 
                        <div key={key} className='flex w-[33%] max-md:w-full items-center my-4'>                          
                          <input name="gatewayCheckbox" checked={selectGateway[data.nccset_id] && selectGateway[data.nccset_id].includes(key + "")} onChange={(e) => checkboxChangeHandler(e, data.nccset_id, key)} type="checkbox" className="mx-2 w-6 h-6 "/>
                          <div className='flex flex-col'>
                            <label htmlFor='gatewayCheckbox'>{typeName}</label>
                            <p className='text-yellow-500'>{merchantno}</p>
                          </div>
                      </div>
                      )}
                    
                    </div>  
                  </div>
                )}
               
              </div>
                    

            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-4'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowBatchEdit(false)}></Button>
              <Button style={{width: "120px"}} label={sysText10002} severity='info' type="submit" onClick={confirmEdit} raised></Button>
            </div>
          </div>
         
        </div>
       
      </Dialog>
    </>
  );
};

export default BatchEditNcc;
