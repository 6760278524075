import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { formatDate } from '../../helper/formatDate';

import FilterInput from '../../components/UI/filterInput';

import { web_12_ncc_orderlist } from '../../api/api'
import PaginatorUI from '../../components/UI/paginatorUI';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import { SplitButton } from 'primereact/splitbutton';
import RecordNccComplete from '../../components/orderManage/recordNcc/recordNccComplete';
import RecordResend from '../../components/orderManage/record/recordResend';
import RecordFreezeNcc from '../../components/orderManage/recordNcc/recordFreezeNcc';
import RecordDetailNcc from '../../components/orderManage/recordNcc/recordDetailNcc';
import useSystemText from '../../helper/useSystemText';
import { MultiSelect } from 'primereact/multiselect';

const RecordNcc = () => {       
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [recordData , setRecordData] = useState([]);
  const [totalData, setTotalData] = useState({})
  const [totalCount, setTotalCount] = useState(0)
  const [filterDate, setFilterDate] = useState([])    
  const [filterNumber, setFilterNumber] = useState("")    
  const [showDetail, setShowDetail] = useState(false)
  const [showComplete, setShowComplete] = useState(false)    
  const [detailData, setDetailData] = useState({})
  const [showFreeze, setShowFreeze] = useState(false)
  const [freezeOrUnfreeze, setFreezeOrUnfreeze] = useState(null)
  const [showResend, setShowResend] = useState(false)
  const [resendData, setResendData] = useState({})        
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10);
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()     

  const sysText10015 = useSystemText(10015)
  const sysText10016 = useSystemText(10016)
  const sysText10017 = useSystemText(10017)
  const sysText10018 = useSystemText(10018)
  const sysText10019 = useSystemText(10019)
  const sysText10020 = useSystemText(10020)
  const sysText10021 = useSystemText(10021)
  
  const sysText201001 = useSystemText(201001)
  const sysText201002 = useSystemText(201002)
  const sysText201003 = useSystemText(201003)
  const sysText201004 = useSystemText(201004)
  const sysText201005 = useSystemText(201005)
  const sysText201006 = useSystemText(201006)
  const sysText201007 = useSystemText(201007)
  const sysText201008 = useSystemText(201008)
  const sysText201009 = useSystemText(201009)
  const sysText201010 = useSystemText(201010)
  const sysText201011 = useSystemText(201011)

  const sysText201012 = useSystemText(201012)
  const sysText201025 = useSystemText(201025)
  const sysText201028 = useSystemText(201028)
  const sysText201035 = useSystemText(201035)
  const sysText201038 = useSystemText(201038)
  const sysText201039 = useSystemText(201039) 

  const ActiveColumn = (item) => {     
          
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.訂單狀態) {
        case 0:
          label = sysText10015
          severity = "secondary"
          break
        case 1:
          label = sysText10016
          severity = "info"
          break
        case 2:
          label = sysText10017
          severity = "success"
          break
        case 3:
          label = sysText10018
          severity = "warning"
          break
        case 4:
          label = sysText10019
          severity = "help"
          break
        case 5:
          label = sysText10020
          severity = "secondary"            
          break
        case 9:
          label = sysText10021
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }     

    const {label, severity} = statusBtnCheck()         

    const showDetailHandler = () => {
      setShowDetail(true)
      setDetailData(item)        
    }

    const showCompleteHandler = () => {
      setShowComplete(true)
      setDetailData(item) 
    }

    const showFreezeHandler = () => {
      setFreezeOrUnfreeze("冻结")
      setShowFreeze(true)
      setDetailData(item) 
    }

    const showResendHandler = () => {
      setShowResend(true)
      setResendData(item)
    }

    const menuItems1_2 = [        
      {
        label: sysText201012,
        command: showDetailHandler          
      },  
      {
        label: sysText201025,
        command: showCompleteHandler,
        disabled: item.訂單狀態 === 2 || item.訂單狀態 === 5
      },     
    ];

    const menuItems3 = [
      {
        label: sysText201025,
        command: showCompleteHandler,
        disabled: item.訂單狀態 === 2 || item.訂單狀態 === 5
      },
    
      {
        label: sysText201028,
        command: showFreezeHandler,
        disabled: item.訂單狀態 === 5
      },
      {
        label: sysText201035,
        command: showResendHandler,
        disabled: !(item.訂單狀態 === 1 || item.訂單狀態 === 2)  
      },         
    ];

    const menuItems7 = [        
      {
        label: sysText201012,
        command: showDetailHandler          
      },         
    ];

    // const menuItems8 = [
    //   {
    //     label: sysText201035,
    //     command: showResendHandler,
    //     disabled: !(item.訂單狀態 === 1 || item.訂單狀態 === 2)
    //   },  
    // ];

    const menuItems9 = [
      {
        label: sysText201025,
        command: showCompleteHandler,
        disabled: item.訂單狀態 === 2 || item.訂單狀態 === 5
      },
      // {
      //   label: '订单详情',
      //   command: showDetailHandler          
      // },
      {
        label: sysText201028,
        command: showFreezeHandler,
        disabled: item.訂單狀態 === 5
      },
      {
        label: sysText201035,
        command: showResendHandler,
        disabled: !(item.訂單狀態 === 1 || item.訂單狀態 === 2)                    
      },         
    ];
    
    return (       
      
      <div className='activeBtn flex flex-col items-center justify-center p-0 w-full whitespace-nowrap'> 
        {(permissions === 1 || permissions === 2) &&           
          <SplitButton label={label} model={menuItems1_2} severity={severity} />      
        }         

        {permissions === 3 &&           
          <SplitButton label={label} model={menuItems3} severity={severity} />      
        }

        {(permissions === 7) &&           
          <SplitButton label={label} model={menuItems7} severity={severity} />      
        }            

        {permissions === 8 &&           
          <Button label={label} severity={severity} />      
        }       

        {permissions === 9 &&           
          <SplitButton label={label} model={menuItems9} severity={severity} />      
        }         
      </div>   
      
    )    
  }      

  const tableColumns = [
    {
      header: sysText201005,
      field: "商戶號",
      style: {minWidth: "100px"},
      enable: [3, 8 ,9] 
    },
    {
      header: sysText201006,
      field: "訂單編號",
      style: {minWidth: "100px"},
      enable: [3, 8 ,9] 
    },
    {
      header: sysText201039,
      field: "系統單號",
      style: {minWidth: "100px"},
    },
    {
      header: sysText201007,
      field: "付款人資訊",
      style: {minWidth: "100px"},
      enable: [3, 8 ,9] 
    },
    {
      header: sysText201008,
      field: "員工資訊",
      style: {minWidth: "300px"},
    },
    {
      header: sysText201009,
      field: "訂單金額",
      style: {minWidth: "100px"},
    },
    {
      header: sysText201010,
      field: "三方套件",
      style: {minWidth: "120px"},
      enable: [3, 9] 
    },
    {
      header: sysText201003,
      field: "訂單時間",
      style: {minWidth: "100px"},
      body: (date) => formatDate(date.訂單時間)
    },
    {
      header: sysText201004,
      field: "完成時間",
      style: {minWidth: "100px"},
      body: (date) => formatDate(date.完成時間)
    },
    {
      header: sysText201011,
      field: "active",
      style: {width: "140px"},
      body: ActiveColumn,
      headerClassName: "centered-header"
    },
  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)


  useEffect(() => {      
    const filterData = {      
      cookie_uuid: Cookies.get('cookie_uuid'),
      cookie_hash: Cookies.get('cookie_hash'),    
      ordernumber: filterNumber,
      start_date: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
      end_date: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime(),
      page: page + 1,
      limit: rows,        
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText} = await web_12_ncc_orderlist(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {                     
          setRecordData(listResponse.data)            
          setTotalCount(listResponse.count)
          setTotalData(listResponse.count_data[0])             
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)      
  }, [first, rows, page, filterDate, filterNumber, isRefresh]);

  return (
    <div className="p-4 max-2xl:py-2 max-2xl:px-0 recordWrap tableWrap">
      {(permissions === 3 || permissions === 8 || permissions === 9) &&
        <div className='flex flex-row max-2xl:justify-center'>
          <div className='px-1 py-1 bg-blue-500 rounded-md mr-2 max-md:text-center'>{sysText201001}{totalData.完成筆數 ?? 0}</div>
          <div className='px-1 py-1 bg-yellow-400 rounded-md max-md:text-center'>{sysText201002}{totalData.完成金額加總 ?? 0}</div>            
        </div>
      }
      {/* MultiSelect組件 value options裡面不能有function */}
      <FilterInput setFilterDate={setFilterDate} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} placeholder={sysText201038}>
        <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 max-2xl:hidden custom-multiselect" display='chip'/>
      </FilterInput>

      <div className='max-2xl:hidden'>
        <DataTable value={recordData} scrollable scrollHeight="550px">
          {visibleColumns.map((row,index) =>            
            <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName}></Column>
          )}              
        </DataTable>
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
      </div>        

      <div className='min-2xl:hidden'>
        <div className='overflow-y-scroll max-md:max-h-[50vh] max-lg:max-h-[600px] max-2xl:max-h-[700px] max-h-[600px]'>
          <div className='border-none'>              
            <table className="text-white w-full">
              <tbody>
                {
                  recordData.map((item) => {
                    return (
                      <tr key={item.訂單編號} className="bg-customColor-bgDark leading-6 rounded-md break-all">
                        <td className="flex flex-col">
                          
                          {/* <p>{formatDate(item.完成時間)}</p> */}
                          {(permissions === 3 || permissions === 8 || permissions === 9) &&
                            <div>{item.商戶號}</div>
                          }
                          {(permissions === 3 || permissions === 8 || permissions === 9) &&   
                            <div>{item.訂單編號}</div>
                          }
                          <div>{item.系統單號}</div>
                          {(permissions === 3 || permissions === 8 || permissions === 9) &&
                            <div>{item.付款人資訊}</div>
                          }                           
                          <div>{item.員工資訊}</div>                                                                                                  
                          <div>金額: {item.訂單金額}</div>
                          {(permissions === 3 || permissions === 9) &&
                            <div>{item.三方套件}</div>
                          }    
                          <div>{formatDate(item.訂單時間)}</div>
                        </td>   
                        <td className="p-0">
                          {ActiveColumn(item)}
                        </td>                 
                      </tr>
                    )                       
                  })
                }
              </tbody>                       
            </table>
          </div>              
        </div>
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> 
      </div>    

      {/* 訂單詳細  */}
      {showDetail && <RecordDetailNcc setShowDetail={setShowDetail} detailData={detailData}/>}           

      {/* 完成訂單 */}
      {showComplete && <RecordNccComplete setShowComplete={setShowComplete} detailData={detailData}/>}

      {/* 凍結 */}
      {showFreeze && <RecordFreezeNcc setShowFreeze={setShowFreeze} rowData={detailData} freezeOrUnfreeze={freezeOrUnfreeze} />}

      {/* 重發回調 */}
      {showResend && <RecordResend setShowResend={setShowResend} resendData={resendData} />}
    </div>
  );
}

export default RecordNcc