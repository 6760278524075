import React, { createContext, useContext, useState, useEffect, useLayoutEffect } from 'react';
import ToastContext from './ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from './loadingContext';
import { web_113_get_lang } from '../api/api';
import Cookies from 'js-cookie';

// 创建一个 Context
const SystemTextContext = createContext();

// 创建一个提供文本数据的 Provider 组件
export const SystemTextProvider = ({ children }) => {
  
  const [systemText, setSystemText] = useState(JSON.parse(sessionStorage.getItem("systemText")));
  const location = useLocation()
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)

  console.log(systemText);

  useLayoutEffect(() => {
    // 模拟从 sessionStorage 获取数据
    const cookie_uuid = Cookies.get("cookie_uuid")
    const cookie_hash = Cookies.get("cookie_hash")
    const helpTextData = sessionStorage.getItem("helpText")
    const systemTextData = sessionStorage.getItem("systemText")
    // console.log("data: ",data);
    if((!helpTextData || !systemTextData) && cookie_uuid && cookie_hash) {

      const getHelpText = async() => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: cookie_uuid,
          cookie_hash: cookie_hash              
        }
                
        try {
          const { listResponse, status, statusText} = await web_113_get_lang(filterData);          
          console.log("listResponse: ", listResponse)          
    
          if (status === 200) {                     
            
            sessionStorage.setItem("helpText", JSON.stringify(listResponse.filter(item => item.id < 10000)))
            sessionStorage.setItem("systemText", JSON.stringify(listResponse.filter(item => item.id >= 10000)))
            setSystemText(listResponse.filter(item => item.id >= 10000))    
            setIsRefresh(prev => !prev)      
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      
      getHelpText()      
      
    }
  }, []);

  return (
    <SystemTextContext.Provider value={systemText}>
      {children}
    </SystemTextContext.Provider>
  );
};

export default SystemTextContext
