import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import GatewaySettingEdit from '../../components/accountManage/gatewaySetting/gatewaySettingEdit';
// import GatewaySettingDelete from '../../components/accountManage/gatewaySetting/gatewaySettingDelete';
import { Dropdown } from 'primereact/dropdown';
import { web_17_ncc_gateway_type, web_18_get_merchant, web_81_get_gateway } from '../../api/api';
import GatewaySettingAdd from '../../components/accountManage/gatewaySetting/gatewaySettingAdd';
import { SplitButton } from 'primereact/splitbutton';
import useSystemText from '../../helper/useSystemText';
import { MultiSelect } from 'primereact/multiselect';
import useTableSize from '../../helper/useTableSize';

const GatewaySetting = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  // const [showDelete, setShowDelete] = useState(false)
  const [gatewaySettingData, setGatewaySettingData] = useState([])
  const [merchantnoData, setMerchantnoData] = useState([])
  const [selectMerchantno, setSelectMerchantno] = useState([])
  const [rowData, setRowData] = useState([])  
  const [selectType, setSelectType] = useState("")
  const [selectState, setSelectState] = useState("")
  const [typeOption, setTypeOption] = useState([])
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const tableScrollY = useTableSize(70, 70)

  const sysText403001 = useSystemText(403001)
  const sysText403002 = useSystemText(403002)
  const sysText403003 = useSystemText(403003)
  const sysText403004 = useSystemText(403004)
  const sysText403005 = useSystemText(403005)
  const sysText403006 = useSystemText(403006)
  const sysText403007 = useSystemText(403007)
  const sysText403008 = useSystemText(403008)  
  const sysText403009 = useSystemText(403009)
  const sysText403010 = useSystemText(403010)
  const sysText403011 = useSystemText(403011)
  const sysText403012 = useSystemText(403012)
  const sysText403015 = useSystemText(403015)
  const sysText403016 = useSystemText(403016)  
  const sysText403017 = useSystemText(403017)
  const sysText403018 = useSystemText(403018)
  const sysText403019 = useSystemText(403019)    

  console.log("selectMerchantno: ", selectMerchantno);

  const statusOption = [
    {
      name: "关闭",
      value: "0"
    },
    {
      name: "启用",
      value: "1"
    }
  ]


  const ActiveColumn = (item) => {

    const statusCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.state) {
        case 0:
          label = "关闭"
          severity = "danger"
          break
        case 1:
          label = "启用"
          severity = "success"
          break
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }   

    const {label, severity} = statusCheck()         

    const editHandler = () => {
      setShowEdit(true)
      setRowData(item)
    }

    // const deleteHandler = () => {
    //   setShowDelete(true)
    //   setRowData(item)
    // }

    const menuItems = [
      {
        label: '编辑',
        command: editHandler,
      },
      // {
      //   label: '删除',
      //   command: deleteHandler,
      // }
    ]

    return (
      <div className='flex flex-col items-center'>
        <SplitButton label={label} model={menuItems} severity={severity} />       
      </div>    
    )
  }
  
  const handleAllCheckboxChange = () => {    
    if (selectMerchantno.length !== merchantnoData.length) {
      setSelectMerchantno(merchantnoData);
    } else {
      setSelectMerchantno([]);
    }
  };

  const handleCheckboxChange = (item) => {
    if (selectMerchantno.find((merchantno) => merchantno === item)) {
      setSelectMerchantno(selectMerchantno.filter((merchantno) => merchantno !== item));  // 如果已選中，則取消選中
    } else {
      setSelectMerchantno([...selectMerchantno, item]);  // 如果未選中，則選中
    }
  };

  const tableColumns = [
    {
      header: sysText403001,
      field: "id",
      style: {minWidth: "80px"},
    },
    {
      header: sysText403002,
      field: "merchantno",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403003,
      field: "gateway_type_name",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403004,
      field: "display_name",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403005,
      field: "col_rate_percent",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403006,
      field: "pur_rate_percent",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403007,
      field: "col_rate_fixe",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403008,
      field: "pur_rate_fixe",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403009,
      field: "min_limit_per_transaction",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403010,
      field: "max_limit_per_transaction",
      style: {minWidth: "100px"},
    },
    {
      header: sysText403011,
      field: "active",
      style: {minWidth: "120px"},
      body: ActiveColumn,
      headerClassName: "centered-header"
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      merchantno: selectMerchantno,
      select_type: selectType,
      select_state: selectState
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_81_get_gateway(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                
          //取商戶List並去除重複merchantno值          
          setGatewaySettingData(listResponse)  
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [selectType, selectState, selectMerchantno, isRefresh]);

  useEffect(() => {   

    const fetchMerchant = async() => {
      
      setIsLoading(true)     
      
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")      
      };

      try {
        const { listResponse, status, statusText } = await web_18_get_merchant(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                
          setMerchantnoData(listResponse)          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    const fetchType = async() => {
      
      setIsLoading(true)     
      
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")      
      };

      try {
        const { listResponse, status, statusText } = await web_17_ncc_gateway_type(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                
          setTypeOption(listResponse)          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchMerchant()
    fetchType()
  }, [isRefresh]);  

  return (
    <div className="cardSettingWrap flex flex-row h-full">
      <div className='flex w-[15%] min-w-[150px] flex-col max-md:hidden'>
      <div className='p-2 text-center bg-customColor-bgDark border border-yellow-400 mb-4'>{sysText403002}</div>
        <div className='flex w-full bg-customColor-bgLightGray overflow-auto'>
          
          <ul className='flex flex-col w-full  space-y-2'>
            <li className='flex justify-center items-center p-2 hover:bg-customColor-bgDark hover:text-yellow-300 duration-150 cursor-pointer' onClick={handleAllCheckboxChange}>
              <input id="checkbox-all-search" type="checkbox" className="mx-2 w-4 h-4"
                checked={merchantnoData.length > 0 && merchantnoData.length === selectMerchantno.length}
                onChange={handleAllCheckboxChange}
              />{sysText403019}
            </li>
            {merchantnoData.map(item => {
              return (
                <li key={item} className='p-2 hover:bg-customColor-bgDark hover:text-yellow-300 duration-150 cursor-pointer' onClick={() => handleCheckboxChange(item)}>
                  <input name="cardCheckbox" type="checkbox" checked={selectMerchantno.includes(item)} onChange={() => handleCheckboxChange(item)} className="mx-2 w-4 h-4 "/>
                  {item}
                </li>
              )
            })}
            
          </ul>
        </div>
      </div>

      <div className='flex flex-col w-[85%] max-md:w-full'>         
        
        <div className='flex flex-row flex-wrap min-md:justify-end mb-1 items-center mx-4 space-x-2'>           
          <Button label={sysText403012} severity='info' className='mx-1 max-md:w-full' onClick={() => {setShowAdd(true)}}></Button>
          <div className='flex flex-row space-x-1 max-md:w-full'>
            <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="min-2xl:w-80 max-2xl:w-40 max-md:hidden custom-multiselect" display='chip'/>
            <Dropdown className='flex items-center custom-dropdown max-md:w-[30%]' value={selectType} onChange={(e) => setSelectType(e.value)} options={typeOption} optionLabel='名稱' optionValue='id' placeholder={sysText403015}></Dropdown>        
            <Dropdown className='flex items-center custom-dropdown max-md:w-[30%]' value={selectState} onChange={(e) => setSelectState(e.value)} options={statusOption} optionLabel='name' placeholder={sysText403016}></Dropdown> 
            
            <Button label={sysText403017} severity='danger' onClick={() => {setSelectType(""); setSelectState("");}} className="flex w-14 h-8 text-sm mx-2 rounded-lg justify-center items-center cursor-pointer whitespace-nowrap"/>
            <Button label={sysText403018} severity='success' onClick={() => setIsRefresh(prev => !prev)} className="flex w-14 h-8 text-sm mx-2 rounded-lg justify-center items-center cursor-pointer whitespace-nowrap"/>
          </div>       
         
        </div>             

        <div className="px-2 tableWrap">              
          <DataTable value={gatewaySettingData} scrollable scrollHeight={tableScrollY}>
            {visibleColumns.map((row,index) =>             
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
            )} 
          </DataTable>        
        </div>

        {/* 新增 */}
        {showAdd && <GatewaySettingAdd setShowAdd={setShowAdd} rowData={rowData}/>}

        {/* 編輯 */}
        {showEdit && <GatewaySettingEdit setShowEdit={setShowEdit} rowData={rowData}/>}

        {/* 刪除 */}
        {/* {showDelete && <GatewaySettingDelete setShowDelete={setShowDelete} rowData={rowData}/>} */}    
      </div>
    </div>
  );
}

export default GatewaySetting