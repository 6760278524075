import React, { useContext, useCallback, useEffect, useState, useRef } from "react";
import { ToastContext } from "../context/ToastContext";
import Cookies from 'js-cookie';
import { web_01_login, checkLoginStatus, web_121_get_page_lang, CLOUDFLARE_KEY } from '../api/api';
import { useLocation, useNavigate } from "react-router-dom";
import LoadingContext from '../context/loadingContext';
import { useFormik } from 'formik';
import { Password } from "primereact/password";
import useSystemText from "../helper/useSystemText";
import { getTOTP } from "../helper/totp";
import Turnstile, { useTurnstile } from "react-turnstile";

const Login = () => {

  const location = useLocation()
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const navigate = useNavigate()  
  const { showToast } = useContext(ToastContext)
  const [urlParams] = useState(new URLSearchParams(location.search)) 
  const turnstile = useTurnstile();

  // console.log(getTOTP());

  const loginApi = async(loginData) => {
    setIsLoading(true)
    
    try {
      const { listResponse, status, statusText} = await web_01_login(loginData);

      if (status === 200) {
        
        Object.keys(Cookies.get()).forEach(cookie => {
          Cookies.remove(cookie);
        });

        Cookies.set('user', loginData.user, { expires: 30 });
        Cookies.set('username', listResponse.name, { expires: 30 });
        Cookies.set('cookie_hash', listResponse.hash, { expires: 30 });
        Cookies.set('cookie_uuid', listResponse.uuid, { expires: 30 });
        Cookies.set('login_state', 'logged_in', { expires: 30 });
        Cookies.set('cookie_permissions', listResponse.perm, { expires: 30 });

        navigate('/home');
      } else {        
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}`)
      }
    } catch (error) {
      console.error('Login error:', error);
      // 在 toast 中顯示異常的狀態碼、訊息和 response 的 body
      showToast("error", `Error ${error.status}`, `Message: ${error.statusText}, Response body: ${JSON.stringify(error.responseBody)}`);
    } finally {
      setIsLoading(false)
    }

    // formik.resetForm();
  }

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
      verificationCode: '',
      captcha: ''
    },
    validate: (data) => {
      let errors = {};
      const regExp = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/

      if (!data.user) {
        errors.user = '必填';
      }

      if (!data.password) {
        errors.password = '必填';
      }

      // if (!data.captcha) {
      //   errors.captcha = '请完成验证';
      // }

      return errors;
    },
    onSubmit: (data) => {
      const loginData = {
        user: data.user,
        user_password: data.password,
        verification_code: data.verificationCode,
        tp_password: getTOTP(),
        captcha: data.captcha
      };

      loginApi(loginData)
    }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const checkCookies = useCallback(async () => {
    const hash = Cookies.get('cookie_hash');
    const uuid = Cookies.get('cookie_uuid');
    if (hash && uuid) {
      const loginData = {
        cookie_hash: hash,
        cookie_uuid: uuid,
        user: '',
        user_password: '',
        verification_code: '',
        tp_password: getTOTP()
      };

      const { user, status } = await checkLoginStatus(loginData);
      if (status === 200) {
        window.location.href = '/home';
      } else {
        Object.keys(Cookies.get()).forEach(cookie => Cookies.remove(cookie));

      }
    }
  }, []);

  useEffect(() => {
    //訪客登入
    if(urlParams.get("user") && urlParams.get("password"))
    {
      const loginData = {
        user: urlParams.get("user"),
        user_password: urlParams.get("password"),
        verification_code: urlParams.get("vc") ?? "",
        tp_password: urlParams.get("otp")     
      };

      loginApi(loginData)
    }else {
      checkCookies();
    }
  }, [checkCookies]); 

  //修改html預設語言
  // useEffect(() => {
  //   const getLang = async() => {
  //     try {
  //       const { listResponse, status, statusText } = await web_121_get_page_lang();          
  //       console.log("listResponse: ", listResponse)          
  
  //       if (status === 200) { 
  //         document.documentElement.lang = "en"; 
  //       } else {
  //         showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )        
  //       }
  //     } catch (error) {          
  //       console.error(location.pathname, error);            
  //     }finally {
  //       setIsLoading(false)
  //     }  
  //   }
  //   getLang()      
  // });

  return (
    
    <div className="flex flex-col h-full">
      <div className="flex flex-col m-auto max-h-[500px]">      
        <div className="relative m-auto bg-customColor-bgLightGray flex max-w-[350px] max-h-[450px] flex-col py-8">
          <h2 className="mb-4 text-center text-3xl  text-white">Login</h2>
          <div className="flex flex-col flex-auto px-16">
            <form onSubmit={formik.handleSubmit} className="flex flex-col">
              <div className="flex flex-col">
                <label className="mb-2" htmlFor="user">帐号</label>
                <input

                  name="user"
                  value={formik.values.user}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  className="p-1"
                  placeholder="请输入帐号"
                />
                {getFormErrorMessage('user')}
              </div>
              <div className="flex flex-col">
                <label className="mb-2" htmlFor="password">密码</label>
                <Password
                  name="password"
                  value={formik.values.password}
                  feedback={false}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  placeholder="请输入密码"
                  style={{ padding: "0px" }}
                />
                {getFormErrorMessage('password')}
              </div>

              <div className="flex flex-col pt-2">
                <label className="mb-2" htmlFor="verificationCode">验证码</label>
                <input

                  name="verificationCode"
                  value={formik.values.verificationCode}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  className="p-1"
                  placeholder="请输入验证码"
                />
                {getFormErrorMessage('verificationCode')}
              </div>          

              <button className="border border-gray-700 rounded-md p-1 hover:border-gray-500" type="submit" disabled={isLoading}>登入</button>
            </form>         
          </div>        
        </div>
        <Turnstile
          sitekey={CLOUDFLARE_KEY}
          theme="dark"
          className="flex justify-center my-8"        
          onVerify={(token) => {            
            console.log("token: ", token);
            formik.setFieldValue('captcha', token)
          }}
          retry="never"         
        />
        {getFormErrorMessage('captcha')}
      </div>
    </div>

  )
}

export default Login