import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import  Dialog from '../../components/dialog';
import { ToastContext } from '../../context/ToastContext';
import FilterInput from '../../components/UI/filterInput';
import PaginatorUI from '../../components/UI/paginatorUI';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { web_08_share_list } from '../../api/api';
import Cookies from 'js-cookie';
import WithdrawRecordRemark from '../../components/share/withdrawRecord/withdrawRecordRemark';
import { formatDate } from '../../helper/formatDate';
import WithdrawRecordComfirm from '../../components/share/withdrawRecord/withdrawRecordConfirm';
import { Dropdown } from 'primereact/dropdown';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const WithdrawRecord = () => {

  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [withdrawData , setWithdrawData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)  
  const [filterDate, setFilterDate] = useState([])
  const [filterNumber, setFilterNumber] = useState("")
  const [filterStatus, setFilterStatus] = useState("")
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmData, setConfirmData] = useState({})    
  const [showRemark, setShowRemark] = useState(false)
  const [remarkData, setRemarkData] = useState({})     
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10);
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const tableScrollY = useTableSize(60, 65)    

  const sysText10024 = useSystemText(10024)
  const sysText10017 = useSystemText(10017)
  const sysText10022 = useSystemText(10022)
  const sysText10038 = useSystemText(10038)

  const sysText501012 = useSystemText(501012)
  const sysText501013 = useSystemText(501013)
  const sysText501014 = useSystemText(501014)
  const sysText501015 = useSystemText(501015)
  const sysText501016 = useSystemText(501016)  
  const sysText501017 = useSystemText(501017)
  const sysText501018 = useSystemText(501018)
  const sysText501019 = useSystemText(501019)
  const sysText501027 = useSystemText(501027)
  
  const statusOption = [
    {name: sysText10038, value: ""},
    {name: sysText10024, value: "1"},
    {name: sysText10017, value: "2"},
    {name: sysText10022, value: "3"}
  ]

  const ActiveColumn = (item) => {         
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''
      switch (parseInt(item.狀態)) {       
        case 1:
          label = sysText10024
          severity = "warning"
          break
        case 2:
          label = sysText10017
          severity = "success"
          break
        case 3:
          label = sysText10022
          severity = "danger"
          break       
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }     

    const {label, severity} = statusBtnCheck()  
    
    const showConfirmHandler = () => {
      setShowConfirm(true)
      setConfirmData(item) 
    }

    const confirmDisable = () => {
      if((item.狀態 == 1) && (permissions === 3 || permissions === 9)) {
        return false
      }else {        
        return true
      }      
    }

    const showRemarkHandler = () => {
      setShowRemark(true)
      setRemarkData(item)        
    }
    
    return (
      
      <div className='activeBtn flex flex-col items-center'>          
        <Button className='w-20' label={label} severity={severity} disabled={confirmDisable()} onClick={showConfirmHandler}/>
        {item.備註 ? <Button style={{color: "lightgrey"}} label={sysText501027} severity="secondary" text raised onClick={showRemarkHandler} /> : ""}        
      </div>
    )
  }      

  const tableColumns = [
    {
      header: sysText501012,
      field: "時間",
      style: {minWidth: "120px"},
      body: (row) => formatDate(row.時間, {second: "numeric"})
    },
    {
      header: sysText501013,
      field: "編號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText501014,
      field: "操作帳號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText501015,
      field: "開戶名稱",
      style: {minWidth: "120px"},
    },
    {
      header: sysText501016,
      field: "銀行名稱",
      style: {minWidth: "120px"},
    },
    {
      header: sysText501017,
      field: "提領卡號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText501018,
      field: "提領金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText501019,
      field: "狀態",
      style: {minWidth: "120px"},
      body: ActiveColumn,
      headerClassName: "centered-header" 
    },   
  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {      
    const filterData = {        
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"), 
      cardnumber: filterNumber,
      start_date: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
      end_date: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime(),
      state: filterStatus,
      page: page + 1,
      limit: rows,        
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText} = await web_08_share_list(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {                     
          setWithdrawData(listResponse.data)            
          setTotalCount(listResponse.count[0])         
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)      
  }, [first, rows, page, filterDate, filterNumber, filterStatus, isRefresh]); 

  return (
  <>
    <div className='tableWrap'>
      <div className="w-full flex justify-end p-2 space-x-1">
        <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 max-2xl:w-40 custom-multiselect" display='chip'/>
        <Dropdown className='custom-dropdown' onChange={(e) => setFilterStatus(e.value)} placeholder="全部状态" options={statusOption} optionLabel='name'/>
      </div>
      {/* <div>
        <FilterInput setFilterDate={setFilterDate} setFilterNumber={setFilterNumber} placeholder="请输入卡号" />
      </div> */}
      <div className=''>
        <DataTable value={withdrawData} scrollable scrollHeight={tableScrollY}>
          {visibleColumns.map((row,index) =>             
            <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
          )}
        </DataTable>
      </div>    

      <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
    </div>

    {showRemark && <WithdrawRecordRemark showRemark={showRemark} remarkData={remarkData} setShowRemark={setShowRemark}/> }

    {showConfirm && <WithdrawRecordComfirm setShowConfirm={setShowConfirm} confirmData={confirmData}/>}
  </>
   

  );
};

export default WithdrawRecord;