import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Dialog from '../../dialog';
import { web_61_verification_code } from '../../../api/api';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const UserManageRevokeKey = (props) => {

  const {setShowRevokeKey, revokeKeyData} = props
  
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const helpText = useHelpText(10)

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText402001 = useSystemText(402001)
  const sysText402002 = useSystemText(402002)
  const sysText402003 = useSystemText(402003)
  const sysText402016 = useSystemText(402016)
  const sysText402017 = useSystemText(402017)
  const sysText402035 = useSystemText(402035)

  const confirmHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      update_uuid: revokeKeyData?.uuid,
      state: "0"
    }    
    
    try {
      const {listResponse, status, statusText} = await web_61_verification_code(filterData)

      if (status === 200) {         
        setIsRefresh(prev => !prev)
        showToast("success", "" + status, `${sysText402035}: \n${revokeKeyData?.用戶名稱}` )          
        setShowRevokeKey(false)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  } 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>            
            <div className='flex flex-row justify-between border-b border-gray-700 p-4 text-lg'>
              <p>{sysText402016}</p>
              <div><HelpIcon tooltipText={helpText}/></div>
            </div>
            <div className='p-4 text-sm space-y-4'>              
              <div>
                <p>{sysText402001}: {revokeKeyData.uuid}</p>
                <p>{sysText402002}: {revokeKeyData.帳號}</p>
                <p>{sysText402003}: {revokeKeyData.用戶名稱}</p>
              </div>   

              <p className=''>{sysText402017}?</p>
              {/* <p className='text-red-700'>*移除验证码后 用户不需要验证码即可登入</p> */}
            </div>            
            
            <div className='border-gray-500 flex justify-end items-center space-x-2 p-4 h-12'>
              <Button style={{width: '80px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowRevokeKey(false)}></Button>
              <Button style={{width: '80px'}} label={sysText10002} severity='info' raised onClick={confirmHandler}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default UserManageRevokeKey;