import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import PaginatorUI from '../../components/UI/paginatorUI'
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';
import { web_56_ncc_edit_percen, web_58_get_revenue_sharing, web_66_get_user_upper_and_down_list } from '../../api/api';
import EditPercent from '../../components/share/shareEdit/editPercent';
import { InputNumber } from 'primereact/inputnumber';
import useSystemText from '../../helper/useSystemText';
import BatchEditPercent from '../../components/share/shareEdit/batchEditGateway';

const ShareEdit = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [shareEditData , setShareEditData] = useState([]);
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10)
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [showEditPercent, setShowEditPercent] = useState(false)
  const [editPercentData, setEditPercentData] = useState({})
  const [editPercentUpperData, setEditPercentUpperData] = useState({})
  const [filterNumber, setFilterNumber] = useState("")
  const [expandedRows, setExpandedRows] = useState(null);
  const [gatewayShareData, setGatewayShareData] = useState([])
  const [showBatchEditGateway, setShowBatchEditGateway] = useState(false)
  const [batchEditGatewayData, setBatchEditGatewayData] = useState([])
  
  const sysText502001 = useSystemText(502001)
  const sysText502002 = useSystemText(502002)
  const sysText502003 = useSystemText(502003)
  const sysText502004 = useSystemText(502004)
  const sysText502005 = useSystemText(502005)  
  const sysText502006 = useSystemText(502006)
  const sysText502007 = useSystemText(502007)
  const sysText502008 = useSystemText(502008)
  const sysText502009 = useSystemText(502009)
  const sysText502010 = useSystemText(502010)  
  const sysText502011 = useSystemText(502011)
  const sysText502012 = useSystemText(502012)
  const sysText502016 = useSystemText(502016)
  const sysText502017 = useSystemText(502017)

  const DownPercentColumn = (item) => {
    if(String(item.uuid)[0] === "2") {
      return item["下線完成訂單分潤(uid=2專用)"]
    }else {      
      return sysText502017
    }
  }

  const ActiveColumn = (item) => {               
       
    const editPercentHandler = () => {
      setEditPercentData(item)
      setEditPercentUpperData(shareEditData.upper)
      setShowEditPercent(true)
    }   

    const batchEditGatewayHandler = () => {
      setBatchEditGatewayData(item)
      setShowBatchEditGateway(true)
    }

    return (      
      <div className='activeBtn flex flex-col items-center justify-around'>          
        <Button label={sysText502008} severity="warning" raised onClick={editPercentHandler} />        
        <Button label={"批量修改通道分潤"} severity="help" raised onClick={batchEditGatewayHandler} />        
      </div>
    )
  }

  //下拉展開該筆所有通道分潤
  const fetchRevenueData = async(data) => {      
    
    console.log("data: ", data);   

    //關閉時data為空，則不執行API
    if(data && data.length > 0) {
      //expandedRows有沒有值判斷下拉展開或關閉
      //只展開當前點擊的拓展
      setExpandedRows([data[data.length-1]])
      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        search_uuid: data[data.length-1]?.uuid      
      };   

      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_58_get_revenue_sharing(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {     
          setGatewayShareData(listResponse)                    
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }  
    }else {
      setExpandedRows([])
    }
    
  }

  const allowExpansion = (rowData) => {    
    return rowData
  };
  
  // U3子單拓展
  const rowExpansionTemplate = (data) => {
    
    const onRowEditComplete = async(e) => {
      setIsLoading(true) 
      let _gatewayShareData = [...gatewayShareData];
      let { newData, index } = e;
  
      _gatewayShareData[index] = newData;
      console.log("newData: ", newData);   

      const filterData = {  
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        percen_uuid: data.uuid,
        percen: newData.趴數,
        gateway_id: newData.gatewayid,
      };
     
        
      try {
        const { listResponse, status, statusText } = await web_56_ncc_edit_percen(filterData);        
        
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {     
          // setExpandedRows(null)
          setGatewayShareData(_gatewayShareData);
          showToast("success", "" + status, `成功更新通道分润: ${newData.顯示名稱} - ${filterData.percen}` )                    
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )          
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }  
    };

    const shareEditor = (options) => {
      return <InputNumber className='' value={options.value} onValueChange={(e) => options.editorCallback(e.value)} minFractionDigits={2} />
    }

    return (
      <>
        <div className='max-lg:px-5 min-lg:px-2 bg-customColor-bgGray'>
          <DataTable value={gatewayShareData || []} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete}>
            <Column field="gatewayid" header={sysText502009}></Column>
            <Column field="顯示名稱" header={sysText502010}></Column>
            <Column style={{ minWidth: "200px" }} field="趴數" header={sysText502011} editor={(options) => shareEditor(options)}></Column>           
            <Column style={{ textAlign: "center" }} field="" header={sysText502007} rowEditor headerClassName='centered-header'></Column>
          </DataTable>      
        </div>      
      </>     
    );
  }; 

  useEffect(() => {    
    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterNumber       
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_66_get_user_upper_and_down_list(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setShareEditData(listResponse)            
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterNumber, isRefresh]);      
  console.log("expandedRows: ", expandedRows);

  return (
    <div className="p-4 recordWrap tableWrap">
      <div className='flex flex-wrap max-md:mb-4'>        
        <div className='flex flex-row items-center min-2xl:ml-auto max-2xl:w-[100%]'>                          
          <FilterInput setFilterNumber={setFilterNumber} showCalendarInput={false} placeholder={sysText502016}/>          
        </div>                        
      </div>

      <div className='flex flex-col space-y-4'>
        <div className='space-y-2'>
          <div className='flex justify-center items-center text-red-400 font-bold rounded-md w-10'>{sysText502004}</div>          
          <div className='flex'>
            <div className='flex flex-row p-2 border border-yellow-400 rounded-md'>
              <p className='min-lg:px-4 border-r max-lg:px-0'>{sysText502001}：{shareEditData.upper && shareEditData.upper[0]?.uuid}</p>
              <p className='min-lg:px-4 border-r max-lg:px-0'>{sysText502003}：{shareEditData.upper && shareEditData.upper[0]?.最上層}</p>
              <p className='min-lg:px-4 border-r max-lg:px-0'>{sysText502004}：{shareEditData.upper && shareEditData.upper[0]?.上層}</p>
              <p className='min-lg:px-4 max-lg:px-0'>{sysText502005}：{shareEditData.upper && shareEditData.upper[0]?.用戶分潤}</p>
            </div>
          </div>

        </div>
           
        <div>
          <div className='flex justify-center items-center text-blue-400 font-bold rounded-md w-10'>{sysText502012}</div>
          <div className='max-lg:hidden'>                       
            {(permissions === 1 || permissions === 2 || permissions === 3) ?
                          
              <DataTable value={shareEditData.down} expandedRows={expandedRows} onRowToggle={(e) => fetchRevenueData(e.data)} rowExpansionTemplate={rowExpansionTemplate} scrollable scrollHeight='550px'>
                 <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="uuid" header={sysText502001}></Column>
                <Column field="用戶名" header={sysText502002}></Column>
                <Column field="最上層" header={sysText502003}></Column>
                <Column field="上層" header={sysText502004}></Column>
                <Column style={{ minWidth: "100px" }} field="用戶分潤" header={sysText502005}></Column>
                <Column style={{ minWidth: "100px" }} field="下線完成訂單分潤(uid=2專用)" header={sysText502006} body={DownPercentColumn}></Column>            
                {permissions !== 2 && 
                  <Column style={{ minWidth: "120px" }} field="active" header={sysText502007} body={ActiveColumn} headerClassName="centered-header"></Column>
                }
              </DataTable>

            : (permissions === 9) ?
                          
              <DataTable value={shareEditData.down} scrollable scrollHeight='550px'>               
                <Column field="uuid" header={sysText502001}></Column>
                <Column field="用戶名" header={sysText502002}></Column>
                <Column field="最上層" header={sysText502003}></Column>
                <Column field="上層" header={sysText502004}></Column>
                <Column style={{ minWidth: "100px" }} field="用戶分潤" header={sysText502005}></Column>
                <Column style={{ minWidth: "100px" }} field="下線完成訂單分潤(uid=2專用)" header={sysText502006} body={DownPercentColumn}></Column>               
                <Column style={{ minWidth: "120px" }} field="active" header={sysText502007} body={ActiveColumn} headerClassName="centered-header"></Column>               
              </DataTable>
            : null
            }               
          </div> 

          
          <div className='min-lg:hidden'>                       
            {(permissions === 1 || permissions === 2 || permissions === 3) ?                          
              <DataTable value={shareEditData.down} expandedRows={expandedRows} onRowToggle={(e) => fetchRevenueData(e.data)} rowExpansionTemplate={rowExpansionTemplate} scrollable scrollHeight='350px'>
                  <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="uuid" header={sysText502001}></Column>
                <Column field="用戶名" header={sysText502002}></Column>
                <Column field="最上層" header={sysText502003}></Column>
                <Column field="上層" header={sysText502004}></Column>
                <Column style={{ minWidth: "100px" }} field="用戶分潤" header={sysText502005}></Column>
                <Column style={{ minWidth: "100px" }} field="下線完成訂單分潤(uid=2專用)" header={sysText502006} body={DownPercentColumn}></Column>            
                {permissions !== 2 && 
                  <Column style={{ minWidth: "120px" }} field="active" header={sysText502007} body={ActiveColumn} headerClassName="centered-header"></Column>
                }
              </DataTable>

            : (permissions === 9) ?                          
              <DataTable value={shareEditData.down} scrollable scrollHeight='350px'>               
                <Column field="uuid" header={sysText502001}></Column>
                <Column field="用戶名" header={sysText502002}></Column>
                <Column field="最上層" header={sysText502003}></Column>
                <Column field="上層" header={sysText502004}></Column>
                <Column style={{ minWidth: "100px" }} field="用戶分潤" header={sysText502005}></Column>
                <Column style={{ minWidth: "100px" }} field="下線完成訂單分潤(uid=2專用)" header={sysText502006} body={DownPercentColumn}></Column>               
                <Column style={{ minWidth: "120px" }} field="active" header={sysText502007} body={ActiveColumn} headerClassName="centered-header"></Column>               
              </DataTable>
            : null
            }              
          </div> 
        </div>
      </div>
      
      {/* 修改分潤比 */}
      {showEditPercent && <EditPercent editPercentData={editPercentData} setShowEditPercent={setShowEditPercent} editPercentUpperData={editPercentUpperData}/>}
      {/* 批量修改通道 */}
      {showBatchEditGateway && <BatchEditPercent batchEditGatewayData={batchEditGatewayData} setShowBatchEditGateway={setShowBatchEditGateway} setExpandedRows={setExpandedRows} />}
    </div>
  );
}

export default ShareEdit


