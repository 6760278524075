import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_10_add_account, web_11_boss_add_account, web_58_get_revenue_sharing, web_75_get_merchantno } from '../../../api/api';
import { Dropdown } from 'primereact/dropdown';
import useSystemText from '../../../helper/useSystemText';

const UserManageAddUser = (props) => {

  const {setShowAddUser, addUserData} = props
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [selectUuid, setSelectUuid] = useState()
  const [merchantnoOption, setMerchantnoOption] = useState([])
  const [selectMerchantno, setSelectMerchantno] = useState()
  const [gatewayData, setGatewayData] = useState([])
  
  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText402018 = useSystemText(402018)
  const sysText402019 = useSystemText(402019)
  const sysText402020 = useSystemText(402020)
  const sysText402003 = useSystemText(402003)  
  const sysText402023 = useSystemText(402023)
  const sysText402024 = useSystemText(402024)
  const sysText402025 = useSystemText(402025)
  const sysText402026 = useSystemText(402026)
  const sysText402027 = useSystemText(402027)
  const sysText402031 = useSystemText(402031)
  const sysText402032 = useSystemText(402032)

  console.log("selectMerchantno: ", selectMerchantno);

  const userOption = [
    {name: "操作人员帐户 1", value: "1"},
    {name: "操作人员帐户 2", value: "2"},
    {name: "主管帐户 3", value: "3"},
    {name: "商户帐户 8", value: "8"},
  ]

  const formik = useFormik({
    initialValues: {
        selectUuid: '',
        user: '',
        username: '',
        password: '',
        profit: '0.00',
        hierarchy: "0",
        gateway: {}
    },
    validate: (data) => {
        let errors = {};
        const percentRegExp = /^(100(\.0{1,2})?|0(\.\d{1,2})?|[1-9]\d?(\.\d{1,2})?)$/
        const hierarchyRegExp = /^(?:[1-9]?[0-9]|99)$/;

        if (!data.user) {								
          errors.user = '必填';
        }else if(data.user.length > 20) {
          errors.user = '不可超過20字';
        }

        if (!data.username) {								
          errors.username = '必填';
        }else if(data.username.length > 10) {
          errors.username = '不可超過10字';
        }

        if (!hierarchyRegExp.test(data.hierarchy)) {								
          errors.hierarchy = '0 ~ 99';
        }

        //讓gatewayData能與data.gateway 陣列key相對應
        let newArray = Object.values(data.gateway)
        newArray.map((percen, index) => {   
          if(gatewayData.length > 0) {
            if(parseFloat(percen) > parseFloat(gatewayData[`${index}`]?.趴數)) {
              errors.gateway = "不能大於上线"
            }
          }           
        })
        
        if (!data.profit) {								
          errors.profit = '必填';
        } else if(!percentRegExp.test(data.profit)) {
          errors.profit = '0 ~ 100.00';
        }

        return errors;
    },
    onSubmit: (data) => {    
      
      let percenJson = ""

      try {
        percenJson = JSON.stringify(data.gateway)        
      }catch(e) {
        showToast("error","通道分润格式錯誤")
      }

      console.log(percenJson);

      if(permissions === 9) {

        const rootAddUser = async () => {   
          setIsLoading(true)
          let filterData = {     
            cookie_uuid: Cookies.get("cookie_uuid"),
            cookie_hash: Cookies.get("cookie_hash"),
            choose_uuid: selectUuid,
            staffuser: data.user,
            staffname: data.username,
            staffpassword: data.password,
            percen: "0",
            hierarchy: data.hierarchy,
            percen_json: percenJson
            // profit: data.profit
          };
          
          if(selectUuid == 8 && selectMerchantno) {
            filterData.merchantno = selectMerchantno.商戶
          }
          
          try {
            
            const { listResponse, status, statusText } = await web_11_boss_add_account(filterData);       
            
            console.log("listResponse: ", listResponse)
    
            if (status === 200) {                     
              setIsRefresh(prev => !prev)
              setShowAddUser(false)              
              showToast("success", "" + status, `${sysText402031}: \n${filterData.staffuser}-${filterData.choose_uuid}` )
              if(filterData.choose_uuid == 1 || filterData.choose_uuid == 2) {
                showToast("info", "" + status, `${sysText402032}`, 20000 )
              }
            } else {
              showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
            }
          } catch (error) {        
            console.error(location.pathname, error);            
          } finally {
            setIsLoading(false)
          }
        }
        rootAddUser()

      }else {
        const addUser = async () => {          

          setIsLoading(true)
          const filterData = {     
            cookie_uuid: Cookies.get("cookie_uuid"),
            cookie_hash: Cookies.get("cookie_hash"),
            staffuser: data.user,
            staffname: data.username,
            staffpassword: data.password,
            percen: "0",
            hierarchy: "0",
            percen_json: percenJson
            // profit: data.profit
          };    

          try {
            
            const { listResponse, status, statusText } = await web_10_add_account(filterData);       
            
            console.log("listResponse: ", listResponse)
    
            if (status === 200) {                     
              setIsRefresh(prev => !prev)
              setShowAddUser(false)
              showToast("success", `${sysText402031}: ${filterData.staffuser}` )
              formik.resetForm();
              if(permissions === 1 || permissions === 2){                
                showToast("info", `${sysText402032}`, null, null, 20000 )
              }
            } else {
              showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
            }
          } catch (error) {        
            console.error(location.pathname, error);            
          } finally {
            setIsLoading(false)
          }
        } 
        addUser()       
      } 

      
    }
    
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	
  const gatewayChangeHandler = (e, data) => {
    let newData = {...formik.values.gateway}
    newData[`${data.gatewayid}`] = e.target.value
    formik.setFieldValue("gateway", newData)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  useEffect(() => {   
    if(permissions === 9 && selectUuid == 8) {    
      setIsLoading(true) 
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")     
      };

      const fetchData = async() => {
        
        try {
          const { listResponse, status, statusText } = await web_75_get_merchantno(filterData);        
          
          console.log("listResponse: ", listResponse)

          if (status === 200) {           
            setMerchantnoOption(listResponse)
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }
      fetchData(filterData)
    }
  }, [selectUuid]);
  
  useEffect(() => {   
      
    setIsLoading(true) 
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      search_uuid: Cookies.get("cookie_uuid")     
    };

    const fetchData = async() => {
      
      try {
        const { listResponse, status, statusText } = await web_58_get_revenue_sharing(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {           
          setGatewayData(listResponse)
          let _gatewayData = {}
          listResponse.map((item) => {
            _gatewayData[`${item.gatewayid}`] = item.趴數 + ""
          })
          formik.setFieldValue("gateway", _gatewayData)
          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
    
  }, [addUserData]);

  console.log("formik.values.gateway: ", formik.values.gateway);
  console.log("gatewayData: ", gatewayData);  
  
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[800px] max-md:w-full max-h-[600px] bg-customColor-bgGray rounded-lg overflow-auto'>
          <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
          
            <div className='border-b border-gray-700 p-4 text-lg'>{sysText402018}</div>          
                    
            <div className='space-y-2 text-sm my-2 px-8 py-4'>
              {permissions === 9 &&
              <div className='flex flex-row space-x-2'>
                <div className='flex flex-col'>
                  <label htmlFor='selectUuid'>{sysText402019}</label>
                  <Dropdown className='custom-dropdown' value={selectUuid} onChange={(e) => setSelectUuid(e.value)} options={userOption} optionLabel="name" placeholder="请选择用户身份"/>                  
                </div>
                {selectUuid == 8 &&
                  <div className='flex flex-col'>
                    <label htmlFor='selectUuid'>{sysText402026}</label>
                    <Dropdown filter className='custom-dropdown' value={selectMerchantno} onChange={(e) => setSelectMerchantno(e.value)} options={merchantnoOption} optionLabel="商戶" placeholder="请选择商户"/>                    
                  </div>
                }
              </div>
              }              

              <div>
                <label htmlFor='user'>{sysText402020}</label>
                <input name="user" value={formik.values.user} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('user')}
              </div>                  
             
              <div>
                <label htmlFor='username'>{sysText402003}</label>
                <input name="username" value={formik.values.username} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('username')}
              </div>

              <div>
                <label htmlFor='password'>{sysText402023}</label>
                <input name="password" value={formik.values.password} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('password')}
              </div>

              {permissions === 9 &&
                <div>
                  <label htmlFor='hierarchy'>{sysText402024}</label>
                  <input name="hierarchy" value={formik.values.hierarchy} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                  {getFormErrorMessage('hierarchy')}
                </div>
              }

              <div>
                <label htmlFor='profit'>{sysText402025}</label>
                <input name="profit" value={formik.values.profit} autoComplete='off' disabled={true} onChange={formik.handleChange} className='w-full opacity-50' type="text" />
                {getFormErrorMessage('profit')} 
              </div>

              <p>{sysText402027}</p>
              <div className='flex flex-row flex-wrap'>                
                {gatewayData.map((item, index) => 
                  <div key={item.gatewayid} className='w-[30%] max-md:w-full m-2'>
                    <label htmlFor={`gateway`}>{item.顯示名稱} <small className='text-yellow-500'>{`最高(${gatewayData[index].趴數})`}</small></label>
                    <input name={`gateway`} value={formik.values.gateway[`${item.gatewayid}`]} autoComplete='off' onChange={(e) => gatewayChangeHandler(e, item)} className='w-full' type="text" />
                    {getFormErrorMessage('gateway')}
                  </div>
                )}             
              </div>
                                        
            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowAddUser(false)}></Button>
              <Button type="submit" style={{width: "120px"}} label={sysText10002} disabled={isLoading} severity='info' raised></Button>
            </div>
          
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default UserManageAddUser;