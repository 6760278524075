import React, { useContext, useEffect, useState } from "react";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_18_get_merchant, web_22_get_card_to_add_order, web_15_add_order} from "../../api/api";
import { Dropdown } from "primereact/dropdown";

const RecordAdd = () =>  {    

    const {showToast} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()		
    const [cardData, setCardData] = useState([])
    const [merchantData, setMerchantData] = useState([])
    
    const [uuidOption, setUuidOption] = useState([])
    const [staffnameOption, setStaffnameOption] = useState([])
    const [staffcardnumberOption, setStaffCardnumberOption] = useState([]) 
    const [staffbanknameOption, setStaffbanknameOption] = useState([])
    console.log(staffbanknameOption);     
    
    const formik = useFormik({
      initialValues: {
          ordernumber: '',
          productprice: '',
          payername: '',
          merchantno: '',
          uuid: '',
          staffname: '',
          staffbankname: '',
          staffcardnumber: '',           
          
      },
      validate: (data) => {
          let errors = {};     
          const numberRegExp = /^\d*(\.\d+)?$/     

          if (!data.ordernumber) {								
            errors.ordernumber = '必填';
          }

          if (!numberRegExp.test(data.productprice)) {
            errors.productprice = '必须是数字'
          }

          if (!data.payername) {								
            errors.payername = '必填';
          }

          if (!data.merchantno) {								
            errors.merchantno = '必填';
          }
          
          if (!data.uuid) {								
            errors.uuid = '必填';
          }

          if (!data.staffname) {								
            errors.staffname = '必填';
          }

          if (!data.staffcardnumber) {								
            errors.staffcardnumber = '必填';
          }

          if (!data.staffbankname) {								
            errors.staffbankname = '必填';
          }
          return errors;
      },     
      
      onSubmit: (data) => {
        
          const filterData = {  
            cookie_uuid: Cookies.get("cookie_uuid"),
            cookie_hash: Cookies.get("cookie_hash"),   
          	uuid: data.uuid,
          	ordernumber: data.ordernumber,
          	productprice: data.productprice,
            payername: data.payername,
          	staffname: data.staffname,
            staffbankname: data.staffbankname,
            staffcardnumber: data.staffcardnumber,
            merchantno: data.merchantno,
            confirmpeople: Cookies.get("cookie_uuid"),
            source: "root"
          };    
      
          const addOrder = async () => {     
            setIsLoading(true)  
          	try {
          		
          		const { listResponse, status, statusText } = await web_15_add_order(filterData);       
              
          		console.log("listResponse: ", listResponse)
      
          		if (status === 200) {                     
          			showToast("success", "" + status, `成功新增一笔订单 编号为:${data.ordernumber}` )
                formik.resetForm();
          		} else {
          			showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          		}
          	} catch (error) {        
          		console.error(location.pathname, error);            
          	} finally {
          		setIsLoading(false)
          	}
          }
          addOrder()  

          
      }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const handleChange = (e, dataIndex) => {     
      // 自定义的 handleChange 函数
      
      const { name, value } = e.target;        
     
      let cardnumberList = []
      let banknameList = []
      let filterList = []
      //判斷哪一個select被選擇，從cardData獲取資料，更改其他欄位option跟值
      switch(name) {
        case "uuid":               
          filterList = cardData.filter(row => row.indexOf(value) >= 0)                  
          console.log("filterList: ", filterList);
          filterList.map((row)=>{
            cardnumberList=([...cardnumberList, row[1]])
            banknameList=([...banknameList, row[3]])
          })         
          setStaffCardnumberOption(cardnumberList)
          setStaffbanknameOption(banknameList)       
          formik.setFieldValue(name, value)   
          formik.setFieldValue("staffname", filterList[0][2])
          formik.setFieldValue("staffcardnumber", filterList[0][1])
          formik.setFieldValue("staffbankname", filterList[0][3])
          break;

        case "staffname":               
          filterList = cardData.filter(row => row.indexOf(value) >= 0)                 
      
          filterList.map((row)=>{
            cardnumberList=([...cardnumberList, row[1]])
            banknameList=([...banknameList, row[3]])
          })         
          setStaffCardnumberOption(cardnumberList)
          setStaffbanknameOption(banknameList)       
          formik.setFieldValue(name, value)   
          formik.setFieldValue("uuid", filterList[0][0])          
          formik.setFieldValue("staffcardnumber", filterList[0][1])
          formik.setFieldValue("staffbankname", filterList[0][3])
          break;

        case "staffcardnumber":               
          filterList = cardData.filter(row => row.indexOf(value) >= 0)                 
      
          filterList.map((row)=>{          
            banknameList=([...banknameList, row[3]])
          })                 
          setStaffbanknameOption(banknameList)       
          formik.setFieldValue(name, value)   
          formik.setFieldValue("uuid", filterList[0][0])          
          formik.setFieldValue("staffname", filterList[0][2])
          formik.setFieldValue("staffbankname", filterList[0][3])
          break;
      }
      console.log(filterList)
      
    };

    useEffect(()=>{
      const web22_fetchData = async () => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash")
        }
             
        try {
          const { listResponse, status, statusText} = await web_22_get_card_to_add_order(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                         
            setCardData(listResponse.data)                                     
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }

      const web18_fetchData = async () => {
        setIsLoading(true) 
        const filterData = {  
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),            
        };   
                      
        try {
          const { listResponse, status, statusText} = await web_18_get_merchant(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) { 
            let merchantList = []      
            listResponse.merchant.map(row => merchantList = [...merchantList, row[0]])                 
            setMerchantData(merchantList)               
                           
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      web22_fetchData()
      web18_fetchData()          
    },[location])

    // 設定選單列表 f3拿到數據並filter去除重複值
    useEffect(()=>{
      let uuidList = []
      let nameList = [] 
      let cardnumberList = []
      let banknameList = []

      cardData.map((row) => {        
        uuidList = [...uuidList, row[0]]
        nameList = [...nameList, row[2]]
        cardnumberList = [...cardnumberList, row[1]]
        banknameList = [...banknameList, row[3]]
      })      
      
      setUuidOption(uuidList.filter((item, index) => uuidList.indexOf(item) === index))
      setStaffnameOption(nameList.filter((item, index) => nameList.indexOf(item) === index))
      setStaffCardnumberOption(cardnumberList.filter((item, index) => cardnumberList.indexOf(item) === index))
      setStaffbanknameOption(banknameList.filter((item, index) => banknameList.indexOf(item) === index))
    },[cardData])    

    return (
        <div className="systeminformationWrap card flex justify-content-center">
          <div className="my-12 mx-10 p-8 border border-gray-700 w-full overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">新增订单 </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row flex-wrap justify-between">
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                  <div>
                    <label htmlFor="ordernumber" className="text-blue-300">订单编号</label>
                  </div>
                               
                  <input
                      id="ordernumber"
                      name="ordernumber"                  
                      className="p-2 w-64"
                      value={formik.values.ordernumber}
                      onChange={formik.handleChange}
                      placeholder="请输入订单编号"
                      autoComplete="off"                      
                  />
                  {getFormErrorMessage('ordernumber')}
                </div>
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="productprice" className="text-blue-300">订单金额</label>
                  </div>             
                  <input
                      id="productprice"
                      name="productprice"                  
                      className="p-2 w-64"
                      value={formik.values.productprice}
                      onChange={formik.handleChange}
                      autoComplete="off" 
                  />
                  {getFormErrorMessage('productprice')}
                </div>
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="payername" className="text-blue-300">付款人姓名</label>
                  </div>             
                  <input
                      id="payername"
                      name="payername"                  
                      className="p-2 w-64"
                      value={formik.values.payername}
                      onChange={formik.handleChange}
                      placeholder="请输入付款人姓名"
                      autoComplete="off" 
                  />
                  {getFormErrorMessage('payername')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="merchantno" className="text-blue-300">商户编号</label>
                  </div>             
                  <Dropdown
                      id="merchantno"
                      name="merchantno"                  
                      className="custom-dropdown w-64"
                      value={formik.values.merchantno}
                      onChange={formik.handleChange}
                      placeholder="请选择商户编号"
                      options={merchantData}
                      filter
                  />
                  {getFormErrorMessage('merchantno')}
                </div>
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="uuid" className="text-blue-300">UUID</label>
                  </div>             
                  <Dropdown
                      id="uuid"
                      name="uuid"                  
                      className="custom-dropdown w-64"
                      value={formik.values.uuid}
                      onChange={handleChange}
                      options={uuidOption}
                      filter
                  />
                  {getFormErrorMessage('uuid')}
                </div>
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="staffname" className="text-blue-300">员工姓名</label>
                  </div>             
                  <Dropdown
                      id="staffname"
                      name="staffname"                  
                      className="custom-dropdown w-64"
                      value={formik.values.staffname}
                      onChange={handleChange}
                      options={staffnameOption}
                      filter
                  />
                  {getFormErrorMessage('staffname')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="staffcardnumber" className="text-blue-300">卡号</label>
                  </div>             
                  <Dropdown
                      id="staffcardnumber"
                      name="staffcardnumber"                  
                      className="custom-dropdown w-64"
                      value={formik.values.staffcardnumber}
                      onChange={handleChange}
                      options={staffcardnumberOption}
                      filter
                  />
                  {getFormErrorMessage('staffcardnumber')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="staffbankname" className="text-blue-300">收款银行</label>
                  </div>             
                  <input
                      id="staffbankname"
                      name="staffbankname"                  
                      className="p-2 w-64 hover:cursor-not-allowed"
                      value={formik.values.staffbankname}
                      onChange={formik.handleChange}
                      disabled={true}
                      autoComplete="off"
                  />
                  {getFormErrorMessage('staffbankname')}
                </div>
              
              </div>
              
              <Button label="新增" type="submit" disabled={isLoading} severity="info" raised className="w-36" />
              
            </form>
          </div>
          
        </div>
    )
}

export default RecordAdd;