import Cookies from 'js-cookie';
import React, { useContext } from 'react';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Dialog from '../dialog';
import { web_47_disable_splitting_of_purchase_order } from '../../api/api';
import { Button } from 'primereact/button';
import useSystemText from '../../helper/useSystemText';

const DisableSplit = (props) => {
  const {setShowDisableSplit, splitData} = props 
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText306004 = useSystemText(306004)
  const sysText306039 = useSystemText(306039)
  const sysText306050 = useSystemText(306050)

  const confirmSplitHandler = async() => {
    setIsLoading(true)
    const filterData = {       
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: splitData.採購單編號     
    };    
            
    try {
      const { listResponse, status, statusText } = await web_47_disable_splitting_of_purchase_order(filterData);        
      console.log(status, statusText);
      if (status === 200) {
        setIsRefresh(prev => !prev) 
        showToast("success", "" + status, `${sysText306050}: \n${splitData.採購單編號}` )
        setShowDisableSplit(false)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
      setShowDisableSplit(false)
    }  
  }

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] min-h-[200px] max-h-[800px] max-md:max-h-[500px] overflow-y-scroll bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText306039}</div>
            <div className='p-6 flex flex-col space-y-4'>              
              <div>
                <p className='text-sm'>{sysText306004}: </p>
                <p className='text-yellow-300'>{splitData?.採購單編號}</p>   
              </div>
                    
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowDisableSplit(false)}></Button>
              <Button style={{width: '120px'}} label={sysText10002} severity='info' raised onClick={confirmSplitHandler}></Button>                            
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DisableSplit;