import React, { useState, useContext, useEffect } from "react";
import { useFormik } from 'formik';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputSwitch } from "primereact/inputswitch";

import Cookies from "js-cookie";
import ToastContext from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_07_change_password, web_107_update_sys_cost, web_108_get_sys_cost, web_113_get_lang, web_114_update_lang, web_118_update_overdue, web_119_get_overdue, web_124_update_pur_order_overdue, web_125_get_pur_order_overdue, web_127, web_128, web_51_get_functionToggle, web_53_update_functionToggle, web_73_get_bonus_set, web_74_update_bonus_set, web_76_get_marquee, web_77_insert_marquee, web_85_get_erp_water168_shop_merchant_data } from "../../api/api";
import LogoutAllConfirm from "../../components/accountManage/systeminformation/logoutAllConfirm";
import CloseChannelConfirm from "../../components/accountManage/systeminformation/closeChannelConfirm";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import useCopy from "../../helper/useCopy";
import HelpIcon from "../../components/UI/helpIcon";
import useHelpText from "../../helper/useHelpText";
import useSystemText from "../../helper/useSystemText";

const Systeminformation = () =>  {    

    const permissions = parseInt(Cookies.get("cookie_permissions")[0])
    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()		    
    const [uploadInput, setUploadInput] = useState()
    const [displayInput, setDisplayInput] = useState()
    const [reserveInput, setReserveInput] = useState()
    const [showLogoutAll, setShowLogoutAll] = useState(false)
    const [showCloseChannel, setShowCloseChannel] = useState(false)
    const [marqueeData, setMarqueeData] = useState([])
    const [marqueeInput, setMarqueeInput] = useState("")
    const [marqueeDate, setMarqueeDate] = useState([])
    const [isEditMarquee, setIsEditMarquee] = useState(false)
    const [systeminformation, setSysteminformation] = useState({})
    const [helpTextPageNameOption, setHelpTextPageNameOption] = useState([])

    const helpText4 = useHelpText(4)
    const helpText18 = useHelpText(18)
    const helpText19 = useHelpText(19)

    const sysText10002 = useSystemText(10002)
    const sysText10044 = useSystemText(10044)
    const sysText10045 = useSystemText(10045)    

    const sysText401001 = useSystemText(401001)
    const sysText401002 = useSystemText(401002)
    const sysText401003 = useSystemText(401003)
    const sysText401004 = useSystemText(401004)    
    const sysText401005 = useSystemText(401005)    
    const sysText401006 = useSystemText(401006)
    const sysText401007 = useSystemText(401007)
    const sysText401008 = useSystemText(401008)
    const sysText401009 = useSystemText(401009)    
    const sysText401010 = useSystemText(401010)    
    const sysText401011 = useSystemText(401011)
    const sysText401012 = useSystemText(401012)
    const sysText401013 = useSystemText(401013)
    const sysText401014 = useSystemText(401014)    
    const sysText401015 = useSystemText(401015)    
    const sysText401016 = useSystemText(401016)
    const sysText401017 = useSystemText(401017)
    const sysText401018 = useSystemText(401018)
    const sysText401019 = useSystemText(401019)    
    const sysText401020 = useSystemText(401020)    
    const sysText401021 = useSystemText(401021)
    const sysText401022 = useSystemText(401022)
    const sysText401023 = useSystemText(401023)
    const sysText401024 = useSystemText(401024)    
    const sysText401025 = useSystemText(401025)    
    const sysText401026 = useSystemText(401026)
    const sysText401027 = useSystemText(401027)
    const sysText401028 = useSystemText(401028)
    const sysText401029 = useSystemText(401029)    
    const sysText401030 = useSystemText(401030)    
    const sysText401031 = useSystemText(401031)
    const sysText401032 = useSystemText(401032)
    const sysText401033 = useSystemText(401033)
    const sysText401034 = useSystemText(401034)    
    const sysText401035 = useSystemText(401035)    
    const sysText401036 = useSystemText(401036)
    const sysText401037 = useSystemText(401037)
    const sysText401038 = useSystemText(401038)
    const sysText401039 = useSystemText(401039)    
    const sysText401040 = useSystemText(401040)    
    const sysText401041 = useSystemText(401041)
    const sysText401042 = useSystemText(401042)
    const sysText401043 = useSystemText(401043)
    const sysText401044 = useSystemText(401044)
    const sysText401045 = useSystemText(401045)
    const sysText401046 = useSystemText(401046)
    const sysText401047 = useSystemText(401047)
    const sysText401048 = useSystemText(401048)
    const sysText401049 = useSystemText(401049)
    const sysText401050 = useSystemText(401050)
    const sysText401051 = useSystemText(401051)
    const sysText401052 = useSystemText(401052)
    const sysText401053 = useSystemText(401053)
    const sysText401054 = useSystemText(401054)
    const sysText401055 = useSystemText(401055)

    
    const {copyHandler} = useCopy()

    const bonusTypeOption = [
      {
        name: sysText401043,
        value: "1"
      },
      {
        name: sysText401044,
        value: "2"
      },
      {
        name: sysText401045,
        value: "3"
      }
    ]

    const displayDate = (date) => {
      if(date.length > 0) {
        const start_date = new Date(date[0]).toLocaleDateString() + " " + new Date(date[0]).getHours() + ":" + new Date(date[0]).getMinutes()
        const end_date = new Date(date[1]).toLocaleDateString() + " " + new Date(date[1]).getHours() + ":" + new Date(date[1]).getMinutes()
        return start_date + " - " + end_date
      }else {
        return "未设定"
      }
      
    }    

    const web_53 = async(data) => {
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id: data.id,
        value: data.value
      }  

      try {
        
        const { listResponse, status, statusText } = await web_53_update_functionToggle(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          showToast("success", "" + status, `${sysText401047} ${data.id}-${data.value}` )
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }      
    }  

    const uploadChangeHandler = async(e) => {  
             
      if(e.target.value === false) {       
        setUploadInput(0)        
      }else if(e.target.value === true) {       
        setUploadInput(1)        
      }      
      web_53({id: 1, value: e.target.value ? "1" : "0"});     
    }

    const displayChangeHandler = async(e) => {      
      if(e.target.value === false) {       
        setDisplayInput(0)        
      }else if(e.target.value === true) {       
        setDisplayInput(1)        
      }      
      web_53({id: 2, value: e.target.value ? "1" : "0"}); 
    }

    const reserveHandler = () => {      
      web_53({id: 4, value: reserveInput})
    }      
    
    const setMarqueeHandler = async() => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        message: marqueeInput,
        start_time: marqueeDate[0] ? new Date(marqueeDate[0]).getTime() : null,
        end_time: marqueeDate[1] ? new Date(marqueeDate[1]).getTime() : null,
        state: "1"
      }
             
      try {
        const { listResponse, status, statusText } = await web_77_insert_marquee(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                 
          setIsRefresh(prev => !prev)
          setIsEditMarquee(false)
          showToast("success", "" + status, `${sysText401048}` )          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
          
    }
    
    //更改密碼
    const userFormik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            verificationCode: ''
        },
        validate: (data) => {
            let errors = {};
            const regExp = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/
            const verificationCodeRegExp = /^\d{1,10}$/ 

            if (!data.oldPassword) {								
              errors.oldPassword = '必填';
            }

						if (!data.newPassword) {								
							errors.newPassword = '必填';
						}else if (!regExp.test(data.newPassword)) {
              errors.newPassword = '大小寫英文與數字，長度不能小於8'
            }

						if (!data.confirmPassword) {								
							errors.confirmPassword = '必填';
						} else if(data.confirmPassword !== data.newPassword) {
							errors.confirmPassword = '与新密码不符';
						}
            
            if(data.verificationCode && !verificationCodeRegExp.test(data.verificationCode))
            {
              errors.verificationCode = '必须为数字'
            }

            return errors;
        },
        onSubmit: (data) => {
          setIsLoading(true)
          const filterData = {     
            cookie_uuid: Cookies.get("cookie_uuid"),
            cookie_hash: Cookies.get("cookie_hash"),
            user_old_password: data.oldPassword,
            user_password: data.newPassword,
            verification_code: data.verificationCode
          };    

          console.log("test1");
      
          const editPassword = async () => {       
            try {
              
              const { listResponse, status, statusText } = await web_07_change_password(filterData);       
              
              console.log("listResponse: ", listResponse)
      
              if (status === 200) {                     
                showToast("success", "" + status, `${sysText401049}` )
                userFormik.resetForm();
              } else {
                showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
              }
            } catch (error) {        
              console.error(location.pathname, error);            
            } finally {
              setIsLoading(false)
            }
          }
          editPassword()              
        }
      });

    const isUserFormFieldInvalid = (name) => !!(userFormik.touched[name] && userFormik.errors[name]);

    const getUserFormErrorMessage = (name) => {
        return isUserFormFieldInvalid(name) ? <small className="p-error">{userFormik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    //獎金設定
    const bonusFormik = useFormik({
      initialValues: {
        selectBonusType: '',
        bonusRate: '0',
        dailyBonus: '0',
        orderBonus: '0',        
        bonusDate: []
      },
      validate: (data) => {
          let errors = {};

          const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/
          const percentRegExp = /^(100(\.0{1,2})?|0(\.\d{1,2})?|[1-9]\d?(\.\d{1,2})?)$/

          if (!data.selectBonusType) {								
            errors.selectBonusType = '必填';
          }

          if (data.bonusRate === null) {								
            errors.bonusRate = '必填';
          }else if (!percentRegExp.test(data.bonusRate)) {
            errors.bonusRate = '0 ~ 100.00'
          }              

          switch(data.selectBonusType) {
            case "1":
              if (data.dailyBonus === null) {								
                errors.dailyBonus = '必填';
              } else if(!numberRegExp.test(data.dailyBonus)) {
                errors.dailyBonus = '必須為數字';
              }
              break
            case "2":
              if (data.bonusRate === null) {								
                errors.bonusRate = '必填';
              }else if (!percentRegExp.test(data.bonusRate)) {
                errors.bonusRate = '0 ~ 100.00'
              } 

              if (data.orderBonus === null) {								
                errors.orderBonus = '必填';
              } else if(!numberRegExp.test(data.orderBonus)) {
                errors.orderBonus = '必須為數字';
              }
              break
            case "3":
              if (data.bonusDate === null) {								
                errors.bonusDate = '必填';
              }
              break    
            default:
              return ""        
          }   

          return errors;
      },
      onSubmit: async(data) => {
        
        setIsLoading(true)
        let filterData = {}
  
        switch(data.selectBonusType) {
          case "1":
            filterData = {
              cookie_uuid: Cookies.get("cookie_uuid"),
              cookie_hash: Cookies.get("cookie_hash"),
              id: data.selectBonusType,
              bonus_per: data.bonusRate,
              bonus_hight_thre: data.dailyBonus            
            }
            break
          case "2":
            filterData = {
              cookie_uuid: Cookies.get("cookie_uuid"),
              cookie_hash: Cookies.get("cookie_hash"),
              id: data.selectBonusType,
              bonus_per: data.bonusRate,            
              bonus_low_thre: data.orderBonus
            }
            break
          case "3":
            filterData = {
              cookie_uuid: Cookies.get("cookie_uuid"),
              cookie_hash: Cookies.get("cookie_hash"),
              id: data.selectBonusType,
              bonus_per: data.bonusRate,            
              start_date: data.bonusDate[0] ? data.bonusDate[0].getTime() : "",
              end_date: data.bonusDate[1] ? data.bonusDate[1].getTime() : ""
            }
            break    
          default:
            return ""        
        }    
                
        try {
          const { listResponse, status, statusText } = await web_74_update_bonus_set(filterData);        
          
          console.log("listResponse: ", listResponse)
  
          if (status === 200) {                     
            showToast("success", "" + status, `${sysText401050}` )
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
              
      }
    });

  const isBonusFormFieldInvalid = (name) => !!(bonusFormik.touched[name] && bonusFormik.errors[name]);

  const getBonusFormErrorMessage = (name) => {
      return isBonusFormFieldInvalid(name) ? <small className="p-error">{bonusFormik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  }; 

  //系統費設定
  const systemFeeFormik = useFormik({
    initialValues: {
      colSystemFeePercent: 0,
      colSystemFixedFee: 0,
      purSystemFeePercent: 0,
      purSystemFixedFee: 0
    },
    validate: (data) => {
      let errors = {};
     
      const percentRegExp = /^(100(\.0{1,2})?|0(\.\d{1,2})?|[1-9]\d?(\.\d{1,2})?)$/
      const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/      

      if (!percentRegExp.test(data.colSystemFeePercent)) {
        errors.colSystemFeePercent = '0 ~ 100.00';
      }

      if (!numberRegExp.test(data.colSystemFixedFee)) {
        errors.colSystemFixedFee = '必须为数字';
      }

      if (!percentRegExp.test(data.purSystemFeePercent)) {
        errors.purSystemFeePercent = '0 ~ 100.00';
      }

      if (!numberRegExp.test(data.purSystemFixedFee)) {
        errors.purSystemFixedFee = '必须为数字';
      }

      return errors;
    },
    onSubmit: (data) => {
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        collection_system_percentage: data.colSystemFeePercent,
        collection_system_fixed_fee: data.colSystemFixedFee,
        payment_system_percentage: data.purSystemFeePercent,
        payment_system_fixed_fee: data.purSystemFixedFee
      };        
      
      const editReceive = async () => {       
        try {
          
          const { listResponse, status, statusText } = await web_107_update_sys_cost(filterData);       
          
          console.log("listResponse: ", listResponse)
  
          if (status === 200) {    
            setIsRefresh(prev => !prev)                 
            showToast("success", "" + status, `${sysText401051}` )              
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }
      editReceive()              
    }
  });

const isSystemFeeFieldInvalid = (name) => !!(systemFeeFormik.touched[name] && systemFeeFormik.errors[name]);

const getSystemFeeErrorMessage = (name) => {
    return isSystemFeeFieldInvalid(name) ? <small className="p-error">{systemFeeFormik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
};	

//幫助文本設定
const helpTextFormik = useFormik({
  initialValues: {
    selectPage: "",
    originText: "",
    displayText: ""
  },
  validate: (data) => {
    let errors = {};   

    if (data.text && data.text.length > 500) {
      errors.text = '不可超過500个字';
    }   

    return errors;
  },
  onSubmit: async(data) => {
    setIsLoading(true)
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      value: data.displayText,
      id: data.selectPage      
    };            
          
    try {
      
      const { listResponse, status, statusText } = await web_114_update_lang(filterData);       
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {    
        setIsRefresh(prev => !prev)                 
        showToast("success", "" + status, `${sysText401052}` )              
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }    
                
  }
});

const isHelpTextFieldInvalid = (name) => !!(helpTextFormik.touched[name] && helpTextFormik.errors[name]);

const getHelpTextErrorMessage = (name) => {
  return isHelpTextFieldInvalid(name) ? <small className="p-error">{helpTextFormik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
};

//逾時設定
const timeoutFormik = useFormik({
  initialValues: {
      orderMinute: 0,
      purMinute: 0
  },
  validate: (data) => {
      let errors = {};
      const numberRegExp = /^\d{1,10}?$/ 

      if (!data.orderMinute) {								
        errors.orderMinute = '必填';
      }else if(!numberRegExp.test(data.orderMinute)){
        errors.orderMinute = '必须为数字';
      }

      if (!data.purMinute) {								
        errors.purMinute = '必填';
      }else if(!numberRegExp.test(data.purMinute)){
        errors.purMinute = '必须为数字';
      }
     
      return errors;
  },
  onSubmit: (data) => {      

    const setOrderTimeout = async () => {    
      setIsLoading(true)  
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        minute: data.orderMinute
      };          
      try {
        
        const { listResponse, status, statusText } = await web_118_update_overdue(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          showToast("success", "" + status, `${sysText401053}` )          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    const setPurTimeout = async () => {    
      setIsLoading(true)  
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        minute: data.purMinute
      };          
      try {
        
        const { listResponse, status, statusText } = await web_124_update_pur_order_overdue(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          showToast("success", "" + status, `${sysText401054}` )          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    setOrderTimeout()
    setPurTimeout()              
  }
});

const isTimeoutFormFieldInvalid = (name) => !!(timeoutFormik.touched[name] && timeoutFormik.errors[name]);

const getTimeoutFormErrorMessage = (name) => {
  return isTimeoutFormFieldInvalid(name) ? <small className="p-error">{timeoutFormik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
};

//登入次数限制設定
const loginLimitFormik = useFormik({
  initialValues: {
      loginLimitTimes: 0,      
  },
  validate: (data) => {
      let errors = {};
      const numberRegExp = /^\d{1,10}?$/ 

      if (!data.loginLimitTimes) {								
        errors.loginLimitTimes = '必填';
      }else if(!numberRegExp.test(data.loginLimitTimes)){
        errors.loginLimitTimes = '必须为数字';
      }else if(parseInt(data.loginLimitTimes) <= 0) {
        errors.loginLimitTimes = '不能小于0';
      }
   
      return errors;
  },
  onSubmit: (data) => {      

    const setLoginLimit = async () => {    
      setIsLoading(true)  
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        count: data.loginLimitTimes
      };          
      try {
        
        const { listResponse, status, statusText } = await web_127(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          showToast("success", "" + status, `${sysText401055}` )          
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }       
    setLoginLimit()

  }

});

const isLoginLimitFormFieldInvalid = (name) => !!(loginLimitFormik.touched[name] && loginLimitFormik.errors[name]);

const getLoginLimitFormErrorMessage = (name) => {
  return isLoginLimitFormFieldInvalid(name) ? <small className="p-error">{loginLimitFormik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
};
    
useEffect(() => {
  const web51_fetchData = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_51_get_functionToggle(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {                     
        const uploadData = listResponse.find(item => item.id === 1)
        setUploadInput(uploadData.值)
        const displayData = listResponse.find(item => item.id === 2)
        setDisplayInput(displayData.值)
        const reserveData = listResponse.find(item => item.id === 4)
        setReserveInput(reserveData.值)  
        
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }     

  const web76_fetchData = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      allornot: "0"
    }  
            
    try {
      const { listResponse, status, statusText } = await web_76_get_marquee(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        setMarqueeData(listResponse)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }

  const web85_fetchData = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")          
    }  
            
    try {
      const { listResponse, status, statusText } = await web_85_get_erp_water168_shop_merchant_data(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        setSysteminformation(listResponse)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }
  
  //系統費設定取值
  const web108_fetchData = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_108_get_sys_cost(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        systemFeeFormik.setFieldValue("colSystemFeePercent", listResponse.代收系統趴數)
        systemFeeFormik.setFieldValue("colSystemFixedFee", listResponse.代收系統固定費用)            
        systemFeeFormik.setFieldValue("purSystemFeePercent", listResponse.代付系統趴數)
        systemFeeFormik.setFieldValue("purSystemFixedFee", listResponse.代付系統固定費用)
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }

  if(permissions === 9){
    web76_fetchData()
    web51_fetchData()
    web85_fetchData()
    web108_fetchData()             
  }
  
}, [isRefresh])


//獎金設定取值
useEffect(() => {
  const web73_fetchData = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_73_get_bonus_set(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        let data = {}
        switch(bonusFormik.values.selectBonusType) {
          case "1":
            data = listResponse.find(item => item.id === 1)
            bonusFormik.setFieldValue("bonusRate", data.獎金率)
            bonusFormik.setFieldValue("dailyBonus", data.超過金額)
            break
          case "2":
            data = listResponse.find(item => item.id === 2)
            bonusFormik.setFieldValue("bonusRate", data.獎金率)
            bonusFormik.setFieldValue("orderBonus", data.小額獎金金額)
            break
          case "3":
            data = listResponse.find(item => item.id === 3)
            bonusFormik.setFieldValue("bonusRate", data.獎金率)               
            bonusFormik.setFieldValue("bonusDate", [new Date(data.起始時間), new Date(data.結束時間)])
            break            
        }           
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }
  web73_fetchData()
}, [bonusFormik.values.selectBonusType, isRefresh])

//跑馬燈設值
useEffect(() => {
  let lastData = marqueeData[marqueeData.length - 1]
  if(lastData) {
    setMarqueeInput(lastData.內容訊息)
    setMarqueeDate([new Date(lastData.開始時間), new Date(lastData.結束時間)])
  }
}, [marqueeData])     

useEffect(() => {
  let lastData = marqueeData[marqueeData.length - 1]
  if(lastData) {
    setMarqueeInput(lastData.內容訊息)
    setMarqueeDate([new Date(lastData.開始時間), new Date(lastData.結束時間)])
  }
}, [marqueeData])


useEffect(() => {
  const { 原始語言: originText, 翻譯過後語言: displayText } = helpTextPageNameOption.find(item => item.id === helpTextFormik.values.selectPage) || {};
  helpTextFormik.setFieldValue("originText", originText)
  helpTextFormik.setFieldValue("displayText", displayText)
}, [helpTextFormik.values.selectPage])


useEffect(() => {

  //取得幫助文本
  const getLang = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_113_get_lang(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        setHelpTextPageNameOption(listResponse.filter(item => item.id < 10000))        
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }
  //逾時設定
  const getOrderOutTime = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_119_get_overdue(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        timeoutFormik.setFieldValue("orderMinute", listResponse)       
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }
  //逾時設定
  const getPurOutTime = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_125_get_pur_order_overdue(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        timeoutFormik.setFieldValue("purMinute", listResponse)       
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }

  //登入次數限制
  const getLoginLimit = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")
    }
            
    try {
      const { listResponse, status, statusText } = await web_128(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {     
        loginLimitFormik.setFieldValue("loginLimitTimes", listResponse)        
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }

  getLang()
  getOrderOutTime()
  getPurOutTime()
  getLoginLimit()

}, [isRefresh])

console.log(loginLimitFormik.values);

return (
    <div className="flex flex-col max-w-[100%] pb-8">
      <div className="top-10 left-16 relative w-20 text-xl text-gray-400 bg-[#18191a]">{sysText401001} </div>
      <div className="mt-6 mx-10 p-8 border border-gray-700 ">
        
        <form onSubmit={userFormik.handleSubmit} className="flex flex-col">
          <label htmlFor="oldPassword">{sysText401002}</label>             
          <Password
              inputId="oldPassword"
              name="oldPassword"                  
              className={classNames({ 'p-invalid': isUserFormFieldInvalid('oldPassword') })}
              value={userFormik.values.oldPassword}
              feedback={false}
              onChange={userFormik.handleChange}
              autoComplete="off"

          />
          {getUserFormErrorMessage('oldPassword')}
          
          <label htmlFor="newPassword">{sysText401003}</label>
          <Password
              inputId="newPassword"
              name="newPassword"                 
              className={classNames({ 'p-invalid': isUserFormFieldInvalid('newPassword') })}
              value={userFormik.values.newPassword}
              feedback={false}
              onChange={userFormik.handleChange}
              autoComplete="off"
          />
          {getUserFormErrorMessage('newPassword')}
          
          <label htmlFor="confirmPassword">{sysText401004}</label>
          <Password
              inputId="confirmPassword"
              name="confirmPassword"                 
              className={classNames({ 'p-invalid': isUserFormFieldInvalid('confirmPassword') })}
              value={userFormik.values.confirmPassword}
              feedback={false}
              onChange={userFormik.handleChange}
              autoComplete="off"
          />
          {getUserFormErrorMessage('confirmPassword')}

          <label htmlFor="verificationCode">{sysText401005}</label>
          <Password
              inputId="verificationCode"
              name="verificationCode"                  
              className={classNames({ 'p-invalid': isUserFormFieldInvalid('verificationCode') })}
              value={userFormik.values.verificationCode}
              feedback={false}
              onChange={userFormik.handleChange}
              autoComplete="off"
          />
          {getUserFormErrorMessage('verificationCode')}
          <Button label={sysText10002} severity="info" type="submit" disabled={isLoading} className="w-32 h-9" />
        </form>
      </div>

      {permissions === 9 && <>
          <div className="top-10 left-16 relative w-32 text-xl text-gray-400 bg-[#18191a]">{sysText401006} </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700  space-y-4">
            <div className="flex flex-row items-center">
              <p>{sysText401007}</p>             
              <div className="mx-2">
                <InputSwitch name="upload" checked={uploadInput === 1} onChange={(e) => {uploadChangeHandler(e)}} className="p-inputswitch" />
              </div>             
            </div>
            <div className="flex flex-row items-center">
              <p>{sysText401008}</p>             
              <div className="mx-2">
                <InputSwitch name="display" checked={displayInput === 1} onChange={(e) => {displayChangeHandler(e)}} className="p-inputswitch" />
              </div>             
            </div>           
          </div>

          <div className="top-10 left-16 relative w-32 text-xl text-gray-400 bg-[#18191a]">{sysText401009} </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 space-y-4">
            <p>{"Secret[API金钥] :"}</p>
            <p>　　{sysText401010}: <a className="text-blue-500 hover:text-yellow-500 duration-300 cursor-pointer" href="/accountManage/ROOT" target="_blank">{systeminformation?.RSA密鑰}</a>{sysText401013}: {systeminformation?.提取碼}</p>
            <p>　　{sysText401011}: {systeminformation?.MD5密鑰}</p>
            <div className="flex flex-row flex-wrap">{sysText401012}:　 
              {systeminformation.回調白名單 && systeminformation.回調白名單.map((item, index) =>
              <p key={index}>
                <span className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{item}</span>　||　
              </p>
              )}
            </div>               
          </div>

          <div className="top-10 left-16 relative w-32 text-xl text-gray-400 bg-[#18191a]">{sysText401014} </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700  space-y-4">
            <form onSubmit={bonusFormik.handleSubmit} className="flex flex-col space-y-6 w-60">
              <Dropdown name="selectBonusType" className='custom-dropdown' value={bonusFormik.values.selectBonusType} onChange={bonusFormik.handleChange} options={bonusTypeOption} optionLabel="name" placeholder={sysText401015}/>
              {bonusFormik.values.selectBonusType &&
                <div className="flex flex-col">
                  <label htmlFor="bonusRate">{sysText401016}</label>
                  <input className="w-60" name="bonusRate" value={bonusFormik.values.bonusRate} onChange={bonusFormik.handleChange} type="text" autoComplete="off"></input>
                  {getBonusFormErrorMessage("bonusRate")}
                </div>                   
              }
              
              {bonusFormik.values.selectBonusType === "1" ?
                <div className="flex flex-col">
                  <label htmlFor="dailyBonus">{sysText401017}</label>
                  <input className="w-60" name="dailyBonus" type="text" value={bonusFormik.values.dailyBonus} onChange={bonusFormik.handleChange} autoComplete="off"></input>
                  {getBonusFormErrorMessage("dailyBonus")}
                </div>
                : bonusFormik.values.selectBonusType === "2" ?
                <div className="flex flex-col">
                  <label htmlFor="orderBonus">{sysText401018}</label>
                  <input className="w-60" name="orderBonus" type="text" value={bonusFormik.values.orderBonus} onChange={bonusFormik.handleChange} autoComplete="off"></input>
                  {getBonusFormErrorMessage("orderBonus")}
                </div>
                : bonusFormik.values.selectBonusType === "3" ?
                <div className="flex flex-col">
                  <label htmlFor="bonusDate">{sysText401019}</label>
                  <Calendar name="bonusDate" className="w-60 custom-calendar" value={bonusFormik.values.bonusDate} onChange={bonusFormik.handleChange} placeholder={sysText10044} selectionMode="range" dateFormat="yy/mm/dd" readOnlyInput></Calendar>
                  {getBonusFormErrorMessage("bonusDate")}
                </div>   
                : null
              }                    
                        
              <div>
                <Button type="submit" style={{width: "100px"}} label={sysText10045} severity="info"></Button>
              </div>                  
            </form>                
          </div>

          <div className="top-10 left-16 relative w-28 text-xl text-gray-400 bg-[#18191a]">{sysText401020} </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 ">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col space-y-4 w-60">
                <div className="flex flex-col">
                  <label htmlFor="reserve">{sysText401021}</label>              
                  <input name="reserve" type="number" value={reserveInput} onChange={(e) => {setReserveInput(e.target.value)}}/>                      
                </div>
                <div>
                  <Button className="w-24 ml-auto" disabled={!reserveInput} label={sysText10045} severity="info" onClick={reserveHandler}></Button>
                </div>
              </div>             
            </div>     
          </div>

          <div className="top-10 left-16 relative w-40 text-xl text-gray-400 bg-[#18191a]">{sysText401022} </div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 ">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col space-y-4">                 
                <Button className="w-24" label={sysText401023} severity="danger" onClick={() => setShowLogoutAll(true)}></Button>
              </div>             
            </div>     
          </div>

          <div className="top-10 left-16 relative w-28 text-xl text-gray-400 bg-[#18191a]">{sysText401024}</div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 ">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col space-y-4">                 
                <Button className="w-32" label={sysText401025} severity="warning" onClick={() => setShowCloseChannel(true)}></Button>
              </div>             
            </div>     
          </div>

          <div className="top-10 left-16 relative w-20 text-xl text-gray-400 bg-[#18191a]">{sysText401026}</div>
          <div className="mt-6 mx-10 p-8 border border-gray-700 ">
            <div className="flex flex-col justify-center">
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-4 mb-4">
                  <div className="flex flex-row items-center justify-between border-b border-gray-700">
                    <h1>{sysText401027}</h1>
                    <div className="space-x-4">
                      {isEditMarquee ? 
                        <>
                        <Button icon="pi pi-check" severity="success" onClick={setMarqueeHandler}></Button>
                        <Button icon="pi pi-times" severity="danger" onClick={() => setIsEditMarquee(false)}></Button>
                        </>      
                      : 
                        <Button icon="pi pi-pencil" severity="help" onClick={() => setIsEditMarquee(true)}></Button>                                               
                      }
                    </div>
                  </div>                      
                  <div className="flex flex-col">
                    <label>{sysText401028}</label>
                    <p className="p-2">
                      {(isEditMarquee === false) ? displayDate(marqueeDate)
                      : <Calendar className="custom-calendar w-72" value={marqueeDate} onChange={(e) => setMarqueeDate(e.value)} showTime hourFormat="24" selectionMode="range" dateFormat="yy/mm/dd"/>
                      }
                    </p>
                    
                  </div>
                  <div className="flex flex-col">
                    <label>{sysText401029}</label>
                    <div className="p-2 w-full">
                      {(isEditMarquee === false) ? <p>{marqueeInput.trim()}</p>
                      : <textarea className="p-2 w-full bg-transparent" value={marqueeInput} onChange={(e) => setMarqueeInput(e.target.value)}></textarea>
                      }
                    </div>
                                    
                  </div>                     
                </div>
                {/* <Button label="新增" severity="info"></Button> */}                    
              </div>    
                        
            </div>     
          </div>               
        </>
      }      

      {(permissions === 9) &&
      <>
        <div className="top-10 left-16 relative w-36 text-xl text-gray-400 bg-[#18191a]">{sysText401030}</div>
        <div className="mt-6 mx-10 p-8 border border-gray-700  space-y-4">
          <form onSubmit={systemFeeFormik.handleSubmit} className="flex flex-col space-y-6 w-60">
          
            <div className="flex flex-col">
              <label htmlFor="colSystemFeePercent">{sysText401031}</label>
              <input className="w-60" name="colSystemFeePercent" value={systemFeeFormik.values.colSystemFeePercent} onChange={systemFeeFormik.handleChange} type="text" autoComplete="off"></input>
              {getSystemFeeErrorMessage('colSystemFeePercent')}
            </div>

            <div className="flex flex-col">
              <label htmlFor="colSystemFixedFee">{sysText401032}</label>
              <input className="w-60" name="colSystemFixedFee" value={systemFeeFormik.values.colSystemFixedFee} onChange={systemFeeFormik.handleChange} type="text" autoComplete="off"></input>
              {getSystemFeeErrorMessage('colSystemFixedFee')}
            </div>

            <div className="flex flex-col">
              <label htmlFor="purSystemFeePercent">{sysText401033}</label>
              <input className="w-60" name="purSystemFeePercent" value={systemFeeFormik.values.purSystemFeePercent} onChange={systemFeeFormik.handleChange} type="text" autoComplete="off"></input>
              {getSystemFeeErrorMessage('purSystemFeePercent')}
            </div>

            <div className="flex flex-col">
              <label htmlFor="purSystemFixedFee">{sysText401034}</label>
              <input className="w-60" name="purSystemFixedFee" value={systemFeeFormik.values.purSystemFixedFee} onChange={systemFeeFormik.handleChange} type="text" autoComplete="off"></input>
              {getSystemFeeErrorMessage('purSystemFixedFee')}
            </div>               
                      
            <div>
              <Button type="submit" style={{width: "100px"}} label={sysText10045} severity="info"></Button>
            </div>                  
          </form>                
        </div>

        <div className="flex flex-row justify-between top-10 left-16 relative w-28 text-xl text-gray-400 bg-[#18191a]">{sysText401035} <span><HelpIcon tooltipText={helpText4}/></span> </div>
        <form onSubmit={helpTextFormik.handleSubmit} className="mt-6 mx-10 p-8 border border-gray-700 space-y-4">
          <div className="flex flex-col space-y-6 w-full">
            <Dropdown name="selectPage" className='custom-dropdown w-60' value={helpTextFormik.values.selectPage} onChange={helpTextFormik.handleChange} options={helpTextPageNameOption} optionLabel="頁面名稱" optionValue="id" placeholder={sysText401036}/>
                       
            <div className="flex flex-col">
              <label htmlFor="originText">{sysText401037}</label>
              <textarea name="originText" className="w-full h-40 bg-transparent opacity-70 cursor-not-allowed" disabled={true} value={helpTextFormik.values.originText} onChange={helpTextFormik.handleChange} autoComplete="off" ></textarea>
              {getHelpTextErrorMessage('originText')}
            </div>

            <div className="flex flex-col">
              <label htmlFor="displayText">{sysText401038}</label>
              <textarea name="displayText" className="w-full h-40 bg-transparent" value={helpTextFormik.values.displayText} onChange={helpTextFormik.handleChange} autoComplete="off" ></textarea>
              {getHelpTextErrorMessage('displayText')}
            </div>          
            <div>
              <p className="flex flex-row">{sysText401039}： <span><HelpIcon tooltipText={helpTextFormik.values.displayText}/></span></p>
            </div>                    
                      
            <div>
              <Button type="submit" style={{width: "100px"}} label={sysText10045} severity="info"></Button>
            </div>                  
          </div>                
        </form>      

        <div className="flex flex-row justify-between top-10 left-16 relative w-28 text-xl text-gray-400 bg-[#18191a]">{sysText401040} <span><HelpIcon tooltipText={helpText18}/></span> </div>
        <form onSubmit={timeoutFormik.handleSubmit} className="mt-6 mx-10 p-8 border border-gray-700 space-y-4">
          <div className="flex flex-col justify-center">
            <div className="flex flex-col space-y-4 w-60">
              <div className="flex flex-col">
                <label htmlFor="orderMinute">{sysText401041}</label>              
                <input name="orderMinute" type="text" value={timeoutFormik.values.orderMinute} onChange={timeoutFormik.handleChange} autoComplete="off"/>                      
                {getTimeoutFormErrorMessage("orderMinute")}
              </div>             
            </div>             
          </div>    
          <div className="flex flex-col justify-center">
            <div className="flex flex-col space-y-4 w-60">
              <div className="flex flex-col">
                <label htmlFor="purMinute">{sysText401042}</label>              
                <input name="purMinute" type="text" value={timeoutFormik.values.purMinute} onChange={timeoutFormik.handleChange} autoComplete="off"/>                      
                {getTimeoutFormErrorMessage("purMinute")}
              </div>
              <div>
                <Button type="submit" className="w-24 ml-auto" label={sysText10045} severity="info"></Button>
              </div>
            </div>             
          </div>      
        </form>

        <div className="flex flex-row justify-between top-10 left-16 relative w-36 text-xl text-gray-400 bg-[#18191a]">{sysText401046} <span><HelpIcon tooltipText={helpText19}/></span> </div>
        <form onSubmit={loginLimitFormik.handleSubmit} className="mt-6 mx-10 p-8 border border-gray-700 space-y-4">
          <div className="flex flex-col justify-center">
            <div className="flex flex-col space-y-4 w-60">
              <div className="flex flex-col">
                <label htmlFor="loginLimitTimes">{sysText401046}</label>              
                <input name="loginLimitTimes" type="text" value={loginLimitFormik.values.loginLimitTimes} onChange={loginLimitFormik.handleChange} autoComplete="off"/>                      
                {getLoginLimitFormErrorMessage("loginLimitTimes")}
              </div>             
              <div>
                <Button type="submit" className="w-24 ml-auto" label={sysText10045} severity="info"></Button>
              </div>
            </div>             
          </div>            
        </form>
      </>
      
      
      }

      {/* 登出所有使用者 */}
      {showLogoutAll && <LogoutAllConfirm setShowLogoutAll={setShowLogoutAll}/>}

      {/* 關閉通道 */}
      {showCloseChannel && <CloseChannelConfirm setShowCloseChannel={setShowCloseChannel}/>}
      
    </div>
  )
}
export default Systeminformation;