import React, { useContext, useEffect, useState } from "react";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_18_get_merchant, web_23_ncc_get_gateway, web_39_ncc_find_user_ncc_set, web_71_ncc_flowrecord_update} from "../../api/api";
import { Dropdown } from "primereact/dropdown";
import useSystemText from "../../helper/useSystemText";
import HelpIcon from "../../components/UI/helpIcon";
import useHelpText from "../../helper/useHelpText";

const AddFlowRecord = () =>  {    

    const {showToast} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()		
    const [infoOption, setInfoOption] = useState([])
    const [gatewayOption, setGatewayOption] = useState([])
    const [merchantData, setMerchantData] = useState([]) 

    const helpText15 = useHelpText(15)

    const sysText203020 = useSystemText(203020)
    const sysText203021 = useSystemText(203021)
    const sysText203022 = useSystemText(203022)
    const sysText203023 = useSystemText(203023)   

    const sysText204001 = useSystemText(204001)
    const sysText204002 = useSystemText(204002)
    const sysText204003 = useSystemText(204003)
    const sysText204004 = useSystemText(204004)
    const sysText204005 = useSystemText(204005)
    const sysText204006 = useSystemText(204006)
    const sysText204007 = useSystemText(204007)
    const sysText204008 = useSystemText(204008)   
    const sysText204009 = useSystemText(204009)   
    
    //3跟4 不須手續費 不用選擇通道
    const operateTypeOption = [
      {
        name: sysText203020,
        value: "1"
      },
      {
        name: sysText203021,
        value: "2"
      },
      {
        name: sysText203022,
        value: "3"
      },
      {
        name: sysText203023,
        value: "4"
      }
    ]    
    
    const formik = useFormik({
      initialValues: {
          merchantno: '',
          cardnumber: '',
          amount: '0',
          transType: '',
          operateType: '',
          remark: '',
          gateway: ''          
          
      },     

      validate: (data) => {
          let errors = {};     
          const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/      

          if (!data.merchantno) {								
            errors.merchantno = '必填';
          }                    

          if (!data.amount) {								
            errors.amount = '必填';
          }else if(!numberRegExp.test(data.amount)) {
            errors.amount = '必須為數字';
          }        

          if (!data.operateType) {								
            errors.operateType = '必填';
          }

          if ((data.operateType === "1" || data.operateType === "2") && !data.gateway) {								
            errors.gateway = '必填';
          }
         
          return errors;
      },     
      
      onSubmit: async(data) => {
        setIsLoading(true)
        const filterData = {  
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),   
          merchantno: data.merchantno,          
          info: data.info.資訊 ?? "",
          orderprice: data.amount,
          operatetype: data.operateType,          
          remark: data.remark,
          gateway: data.gateway?.id ? data.gateway?.id : "0"
        };         
            
        try {
          
          const { listResponse, status, statusText } = await web_71_ncc_flowrecord_update(filterData);       
          
          console.log("listResponse: ", listResponse)
  
          if (status === 200) {                     
            showToast("success", "" + status, `${sysText204009}: ` )
            formik.resetForm();
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }                  
      }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    useEffect(()=>{

      const web39_fetchData = async () => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          card: "9",
          page: "1",
          limit: "100000000",
          select: ""
        }
     
        try {
          const { listResponse, status, statusText} = await web_39_ncc_find_user_ncc_set(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                         
            setInfoOption(listResponse.data.map(row => { return {name: `${row.用戶名稱} - ${row.資訊}`, value: row} }))                                     
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }

      const web18_fetchData = async () => {
        setIsLoading(true)
        const filterData = {  
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),            
        };    
            
        try {
          const { listResponse, status, statusText} = await web_18_get_merchant(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                            
            setMerchantData(listResponse)                           
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      
      web39_fetchData()
      web18_fetchData()          
    },[location])

    useEffect(() => {
      const web23_fetchData = async () => {
        setIsLoading(true)
        const filterData = {  
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          id_list: formik.values.info.通道            
        };    
            
        try {
          const { listResponse, status, statusText} = await web_23_ncc_get_gateway(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                             
            setGatewayOption(listResponse.map((row) => {return {name: row.顯示名稱, value: row}}))
                           
          } else {
            showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      
      if(formik.values.operateType === "3" || formik.values.operateType === "4") {
        formik.setFieldValue("info", "")
        formik.setFieldValue("gateway", "")
      }
      

      if(formik.values.info) {
        // setSelectGatewayData(formik.values.info)
        // formik.setFieldValue("info", formik.values.info.id)
        web23_fetchData() 
      }   
    },[formik.values.operateType, formik.values.info])

    useEffect(() => {
      if(formik.values.gateway) {
        formik.setFieldValue("merchantno", formik.values.gateway.商戶代號)
        console.log("formik.values.gateway.商戶代號: ", formik.values.gateway.商戶代號);
      }
    }, [formik.values.gateway])

    // 設定選單列表 f3拿到數據並filter去除重複值
    // useEffect(()=>{
    //   let uuidList = []
    //   let nameList = [] 
    //   let cardnumberList = []
    //   let banknameList = []

    //   cardData.map((row) => {        
    //     uuidList = [...uuidList, row[0]]
    //     nameList = [...nameList, row[2]]
    //     cardnumberList = [...cardnumberList, row[1]]
    //     banknameList = [...banknameList, row[3]]
    //   })      

      
    //   setUuidOption(uuidList.filter((item, index) => uuidList.indexOf(item) === index))
    //   setStaffnameOption(nameList.filter((item, index) => nameList.indexOf(item) === index))
    //   setStaffCardnumberOption(cardnumberList.filter((item, index) => cardnumberList.indexOf(item) === index))
    //   setStaffbanknameOption(banknameList.filter((item, index) => banknameList.indexOf(item) === index))
    // },[cardData])    

    console.log("formik.values: ", formik.values);

    return (
        <div className="systeminformationWrap card flex justify-content-center flex-col px-10">
          <div className="my-8 p-8 border border-gray-700 w-full overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">{sysText204001} </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row flex-wrap justify-between">

                <div className="flex flex-col w-[100%] max-md:w-[100%]">
                   <div className="flex flex-row space-x-2">
                    <label htmlFor="operateType" className="text-blue-300">{sysText204002}</label>
                    <HelpIcon tooltipText={helpText15}/>
                  </div>             
                  <Dropdown
                      id="operateType"
                      name="operateType"                  
                      className="w-60 custom-dropdown"
                      value={formik.values.operateType}
                      onChange={formik.handleChange}
                      options={operateTypeOption}
                      optionLabel="name"
                      placeholder={sysText204002}                      
                  />
                  {getFormErrorMessage('operateType')}
                </div>

                <div className="flex flex-col w-[50%] max-md:w-[100%]">
                  <div>
                    <label htmlFor="merchantno" className="text-blue-300">{sysText204003}</label>
                  </div>                               
                  <Dropdown
                      id="merchantno"
                      name="merchantno"                                        
                      className="custom-dropdown w-64"
                      value={formik.values.merchantno}
                      onChange={formik.handleChange}
                      options={merchantData}
                      placeholder={sysText204003}
                      filter
                      disabled={formik.values.operateType === "1" || formik.values.operateType === "2"}                                          
                  />
                  {getFormErrorMessage('merchantno')}
                </div>

                <div className="flex flex-col w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="info" className="text-blue-300">{sysText204004}</label>
                  </div>             
                  <Dropdown
                      id="info"
                      name="info"                  
                      className="w-80 custom-dropdown"
                      value={formik.values.info}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      placeholder={sysText204004}
                      options={infoOption}
                      optionLabel="name"
                      filter 
                      disabled={formik.values.operateType === "3" || formik.values.operateType === "4"}
                  />
                  {getFormErrorMessage('info')}
                </div>

                <div className="flex flex-col w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="gateway" className="text-blue-300">{sysText204005}</label>
                  </div>             
                  <Dropdown
                      id="gateway"
                      name="gateway"                  
                      className="w-64 custom-dropdown"
                      value={formik.values.gateway}
                      onChange={formik.handleChange}
                      placeholder={sysText204005}
                      options={gatewayOption}
                      optionLabel="name"
                      optionValue="value"                    
                      disabled={!formik.values.info}
                  />
                  {getFormErrorMessage('gateway')}
                </div>            

                <div className="flex flex-col w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="amount" className="text-blue-300">{sysText204006}</label>
                  </div>             
                  <input
                      id="amount"
                      name="amount"
                      type="text"                  
                      className="p-2 w-64"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      placeholder={sysText204006}
                      autoComplete="off" 
                  />
                  {getFormErrorMessage('amount')}
                </div>                  

                <div className="flex flex-col w-[100%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="remark" className="text-blue-300">{sysText204007}</label>
                  </div>             
                  <textarea 
                      id="remark"
                      name="remark"                                       
                      className="p-2 w-full bg-transparent"
                      value={formik.values.remark}
                      onChange={formik.handleChange}                      
                      autoComplete="off" 
                  />
                  {getFormErrorMessage('remark')}
                </div>      
              
              </div>
              
              <Button label={sysText204008} type="submit" disabled={isLoading} severity="info" raised className="w-36" />
              
            </form>           
          </div>         
          
        </div>
    )
}

export default AddFlowRecord;