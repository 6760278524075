import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { useFormik } from 'formik';
import { web_105_update_brushing_order } from '../../../api/api';
import { Dropdown } from 'primereact/dropdown';
import useSystemText from '../../../helper/useSystemText';

const RiskControlEdit = (props) => {

  const {setShowEditRiskControl, rowData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText706001 = useSystemText(706001)
  const sysText706002 = useSystemText(706002)
  const sysText706003 = useSystemText(706003)
  const sysText706004 = useSystemText(706004)
  const sysText706027 = useSystemText(706027)
  const sysText706030 = useSystemText(706030)

  const riskControlTypeOption = [
    {
      name: "付款人姓名黑名单",
      value: "1"
    }
  ]

  const formik = useFormik({
    initialValues: {
      riskControlType: rowData.brushing_type,
      riskControlData: rowData.風控數據,
      blockTime: rowData.ttl,
      remark: rowData.note,
      // information: rowData.blocked_orders       
    },

    validate: (data) => {
        let errors = {};     
        const regex = /^(?!0$)\d{1,10}$|^-1$/;    

        if (!data.riskControlData) {								
          errors.riskControlData = '必填';
        }

        if (!regex.test(data.blockTime)) {
          errors.blockTime = '必须是数字，最多10位數'
          // errors.blockTime = '必须是数字或-1(永久)'
        }

        if (data.remark && data.remark.length > 200) {								
           errors.remark = '不可超過200字';
        }
      
        return errors;
    },     
    
    onSubmit: async(data) => {
      setIsLoading(true) 

      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        old_depositname: rowData.風控數據,        
        depositname: data.riskControlData,
        block_second: data.blockTime,
        note: data.remark,
        // information: data.information 
      };    
           
      try {
        const { listResponse, status, statusText } = await web_105_update_brushing_order(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {  
          setIsRefresh(prev => !prev)
          showToast("success", `${sysText706027}: \n${filterData.depositname}`)
          setShowEditRiskControl(false)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    
      
    }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full bg-customColor-bgGray rounded-lg'>
          <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
            <div className='border-b border-gray-500 text-lg p-6'>{sysText706030}</div>
            <div className='p-6 space-y-2 overflow-y-scroll max-h-[600px]'> 

              <div className='flex flex-col'>
                <label htmlFor="riskControlType">{sysText706001}</label>    
                <Dropdown className='custom-dropdown' name="riskControlType" disabled={true} value={formik.values.riskControlType} options={riskControlTypeOption} optionLabel='name' optionValue='value' onChange={formik.handleChange} placeholder='请选择风控类型' autoComplete='off'/>
                {getFormErrorMessage('riskControlType')}  
              </div>         
              
              <div>
                <label htmlFor="riskControlData">{sysText706002}</label>                       
                <input id="riskControlData" name="riskControlData" disabled={true} className="p-2 w-full cursor-not-allowed opacity-70" value={formik.values.riskControlData} onChange={formik.handleChange} placeholder="请输入风控数据" autoComplete="off"/>
                {getFormErrorMessage('riskControlData')}  
              </div>

              <div>
                <label htmlFor="blockTime">{sysText706003}</label>                       
                <input id="blockTime" name="blockTime" className="p-2 w-full" value={formik.values.blockTime} onChange={formik.handleChange} placeholder="请输入封锁时间" autoComplete="off"/>
                {getFormErrorMessage('blockTime')}  
              </div> 

              <div className="flex flex-col w-[100%] max-md:w-[100%]">
                <div>
                  <label htmlFor="remark">{sysText706004}</label>
                </div>             
                <textarea id="remark" name="remark" className="p-2 w-full bg-transparent" value={formik.values.remark} onChange={formik.handleChange} autoComplete="off"/>
                {getFormErrorMessage('remark')}
              </div>   

              {/* <div>
                <label htmlFor="information">相关资讯</label>                       
                <input id="information" name="information" className="p-2 w-full" value={formik.values.information} onChange={formik.handleChange} placeholder="" autoComplete="off"/>
                {getFormErrorMessage('information')}  
              </div>                       */}
              
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowEditRiskControl(false)}></Button>
              <Button type="submit" style={{width: '120px'}} label={sysText10002} severity='info' raised></Button>             
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default RiskControlEdit;