import React, { useContext, useEffect, useState } from "react";
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { web_69_get_report } from "../../api/api";
import { Dropdown } from "primereact/dropdown";
import useSystemText from "../../helper/useSystemText";

const ReportDownload = () =>  {    

    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()		
    const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
    const [selectReportType, setSelectReportType] = useState("")
    const [filterDate, setFilterDate] = useState(null);
    
    const sysText704000 = useSystemText(704000)
    const sysText704001 = useSystemText(704001)
    const sysText704002 = useSystemText(704002)
    const sysText704003 = useSystemText(704003)
    const sysText704004 = useSystemText(704004)
    const sysText704005 = useSystemText(704005)
    const sysText704006 = useSystemText(704006)
    const sysText704007 = useSystemText(704007)
    const sysText704008 = useSystemText(704008)
    const sysText704009 = useSystemText(704009)
    const sysText704010 = useSystemText(704010)
    const sysText704011 = useSystemText(704011)
    const sysText704012 = useSystemText(704012)
    
    

    console.log("selectReportType: ", selectReportType);
    
    const reportTypeOption = [
      {
        name: sysText704004,
        id: "1"
      },
      {
        name: sysText704005,
        id: "2"
      },
      {
        name: sysText704006,
        id: "3"
      },
      {
        name: sysText704007,
        id: "4"
      },
      {
        name: sysText704008,
        id: "5"
      },
      {
        name: sysText704009,
        id: "6"
      },
      {
        name: sysText704010,
        id: "7"
      },
      {
        name: sysText704011,
        id: "8"
      },
      {
        name: sysText704012,
        id: "9"
      },
    ]
    
    const reportTypeOption_8 = [
      {
        name: sysText704004,
        id: "1"
      }     
    ]
    
    const handleDateChange = (e) => {
      const range = e.target.value;
      // 確保 range 包含兩個日期
      if (range && range.length === 2 && range[0] && range[1]) {
          // 如果选择的日期范围超过一个月
          if (range[1].getTime() - range[0].getTime() > 31 * 24 * 60 * 60 * 1000) {
              const newEndDate = new Date(range[0]);
              newEndDate.setDate(newEndDate.getDate() + 30);
              setFilterDate([range[0], newEndDate]);
          } else {
              setFilterDate(range);
          }
      } else {
          // 如果 range 不包含兩個日期，則直接設定為選擇的日期
          setFilterDate(range);
      }
    };
   
    const downloadHandler = async() => {
      setIsLoading(true) 
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        reportType: selectReportType.id,
        start_date: filterDate?.[0] !== undefined ? new Date(filterDate[0]).getTime() : -2209017600000,
        end_date: filterDate?.[1] !== undefined ? new Date(filterDate[1]).getTime() : new Date().getTime(),
        typeName: selectReportType.name
      }       
            
      try {
        const { listResponse, status, statusText } = await web_69_get_report(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }      
    }
      
    return (
        <div className="systeminformationWrap card flex justify-content-center">
          <div className="my-12 mx-10 p-8 border border-gray-700 w-[50%] max-md:w-full overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">{sysText704000}</div>
              <div className="flex flex-col space-y-6">
                {permissions === 8 ? 
                  <Dropdown className="w-full custom-dropdown" name="reportType" value={selectReportType} onChange={(e) => setSelectReportType(e.value)} options={reportTypeOption_8} optionLabel="name" placeholder={sysText704001}/>                
                  :
                  <Dropdown className="w-full custom-dropdown" name="reportType" value={selectReportType} onChange={(e) => setSelectReportType(e.value)} options={reportTypeOption} optionLabel="name" placeholder={sysText704001}/>
                }
                <Calendar className="custom-calendar" value={filterDate} onChange={handleDateChange} placeholder={sysText704002} selectionMode="range" dateFormat="yy/mm/dd" readOnlyInput maxDate={new Date()}></Calendar>               
                <div>
                  <Button label={sysText704003} type="submit" onClick={downloadHandler} disabled={isLoading} severity="info" raised className="w-full"/>
                </div>
              </div>
          </div>          
        </div>
    )
}

export default ReportDownload;