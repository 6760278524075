import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';

const PurchaseMateSplit = (props) => {

  const {setShowSplit, splitDataList ,setSplitDataList, currentSplitId} = props
  const {showToast} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [minSplitAmount, setMinSplitAmount] = useState(0)
  const [maxSplitNum, setMaxSplitNum] = useState(0)   
  const [confirmBtn, setConfirmBtn] = useState(true)

  const confirmHandler = () => {    
    let newData = splitDataList
    const result = newData.findIndex(item => item.id === currentSplitId);

    if (result !== -1) {
      newData[result].minSplitAmount = minSplitAmount
      newData[result].maxSplitNum = maxSplitNum
    } else {
      newData.push({id: currentSplitId, minSplitAmount: minSplitAmount, maxSplitNum: maxSplitNum})
    }
    console.log("newData: ", newData)
    setSplitDataList(newData)
    setShowSplit(false)
  }

  useEffect(()=>{    
    const result = splitDataList.findIndex(item => item.id === currentSplitId);
    if (result !== -1) {
      setMinSplitAmount(splitDataList[result].minSplitAmount)
      setMaxSplitNum(splitDataList[result].maxSplitNum)  
    }   
  },[splitDataList])
 
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] min-h-[300px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>拆分设定</div>
            <div className='p-6 space-y-4 flex-1'>              
              <p>最小拆分金额</p>
              <input type="number" value={minSplitAmount} onChange={(e) => setMinSplitAmount(e.target.value)} className='w-full' />
              <p>最大拆分数</p>
              <input type="number" value={maxSplitNum} onChange={(e) => setMaxSplitNum(e.target.value)} className='w-full' />
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center h-auto py-2'>
              <Button style={{width: '120px'}} label='取消' severity='secondary' raised onClick={() => setShowSplit(false)}></Button>
              <Button style={{width: '120px'}} label='确认' severity='info' raised onClick={confirmHandler}></Button>              
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseMateSplit;