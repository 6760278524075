import React, { useState, useEffect, useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { web_126, web_39_find_user_card, web_39_ncc_find_user_ncc_set, web_52_get_all_uuid, web_89_channel_non_card_to_channel_card, web_90_total_ncc_balances } from '../../../api/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';


const ChangeCard = (props) => {

  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {setShowChangeCard} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [uuidOption, setUuidOption] = useState([])
  const [selectUuid, setSelectUuid] = useState("")
  const [balance, setBalance] = useState("")
  const [cardCcData, setCardCcData] = useState([])  
  const [selectCard, setSelectCard] = useState("")
  const [cardNccData, setCardNccData] = useState([])
  const [info, setInfo] = useState(null)  
  const location = useLocation()
  const helpText = useHelpText(14)

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText701003 = useSystemText(701003)
  const sysText701005 = useSystemText(701005)
  const sysText701007 = useSystemText(701007)
  const sysText701009 = useSystemText(701009)
  const sysText701031 = useSystemText(701031)
  const sysText701032 = useSystemText(701032)
  const sysText701041 = useSystemText(701041)

  const InfoColumn = (rowData) => {
    return <Button label='资讯详情' severity='info' text raised onClick={(event) => { 
      try{
        const data = JSON.parse(rowData.資訊)
        setInfo(data)
      }catch (e) {
        setInfo(null)
        showToast("error", "资料格式错误无法显示")
      }
    }}></Button>
  }

  const InfoDialog = ({ info, setInfo }) => {    
    if (info === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setInfo(null)}
        header="资讯详情"
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>资讯详情</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {Object.keys(info).map((key) => 
              <p>{`${key}: ${info[key]}`}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const dailyCashColumn = (item) => {
    return <div>{item.當前收款額 + "/" + item.日收款額上限}</div>
  }

  const confirmSubmit = async() => {
    setIsLoading(true)  
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      operating_uuid: selectUuid,
      card_id: selectCard  
    }        
     
    try {
      const { listResponse, status, statusText } = await web_89_channel_non_card_to_channel_card(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {          
        showToast("success",`${sysText701041}: \n${filterData.card_id}` )
      } else {
        showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }       
    setShowChangeCard(false)
  }  

  const balanceCheck = () => {
    return balance && parseFloat(balance) && selectCard > 0 ? false : true    
  }

   useEffect(() => {          

    const fetchUuidData = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")
                  
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_52_get_all_uuid(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {
          const newData = listResponse.map((item => {return {name: `${item.uuid} - ${item.用戶姓名}`, value: item.uuid}}))  
          setUuidOption(newData)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchUuidData()    
  }, [isRefresh]);  
  

  useEffect(() => {
    const fetchBalance = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        operating_uuid: selectUuid                  
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_90_total_ncc_balances(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {  
          setBalance(listResponse.總餘額)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
    const fetchCardCc = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        select: selectUuid,        
        card: "1"                  
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_126(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {  
          const newData = listResponse.data.map(item => {
            try {
              return {name: Object.values(JSON.parse(item.資訊)).join(" - "), value: item.id}
            } catch (e) {
              return null
            }
            
          })       
          setCardCcData(newData)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    const fetchCardNcc = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        select: selectUuid,        
        card: "0"          
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_126(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {         
          setCardNccData(listResponse.data)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchBalance()
    if (selectUuid) {
      fetchCardCc()
      fetchCardNcc()   
    }
   
  }, [selectUuid, isRefresh]);  

  console.log("cardCcData: ", cardCcData);

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[40%] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-lg overflow-auto'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between border-b border-gray-700 px-8 py-4 text-lg'>
              <h2>{sysText701031}</h2>
              <div><HelpIcon tooltipText={helpText}/></div>
            </div>
                    
            <div className='flex flex-col items-center space-y-4 text-sm my-2 px-8 py-4 h-[60vh] overflow-y-scroll'>      
              <Dropdown className='custom-dropdown' placeholder='选择uuid' value={selectUuid} onChange={(e) => setSelectUuid(e.target.value)} options={uuidOption} optionLabel='name' optionValue="value"></Dropdown>
              <div>{sysText701032}: <span className='text-yellow-500'>{balance ? balance : "0"}</span></div>
              {selectUuid && 
                <Dropdown className='custom-dropdown' placeholder='选择通道卡' value={selectCard} onChange={(e) => setSelectCard(e.target.value)} options={cardCcData} optionLabel='name' optionValue="value"></Dropdown>
              }
              {selectUuid && 
                <div className='tableWrap w-full'>
                  <DataTable value={cardNccData} scrollable scrollHeight='400px' >                  
                    <Column style={{ minWidth: "120px" }} field="顯示名稱" sortable header={sysText701003}></Column>
                    <Column style={{ minWidth: "120px" }} field="資訊" header={sysText701005} body={InfoColumn}></Column>                 
                    <Column style={{ minWidth: "120px" }} field="" header={sysText701007} body={dailyCashColumn}></Column>
                    <Column style={{ minWidth: "120px" }} field="餘額" sortable header={sysText701009}></Column>                 
                  </DataTable>

                  <InfoDialog info={info} setInfo={setInfo}></InfoDialog>
                </div>
              }
             
            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowChangeCard(false)}></Button>
              <Button style={{width: "120px"}} label={sysText10002} severity='info' type="submit" onClick={confirmSubmit} disabled={balanceCheck()} raised></Button>
            </div>
          </div>
         
        </div>
       
      </Dialog>
    </>
  );
};

export default ChangeCard;
