import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_111_get_3p_def_statistical } from '../../api/api';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';
import ThirdPartyResultEdit from '../../components/dashboard/thirdPartyResult/thirdPartyResultEdit';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';


const ThirdPartyResult = () => {
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [thirdPartyResultData , setThirdPartyResultData] = useState([])
  const [editData, setEditData] = useState({}) 
  const [showEdit, setShowEdit] = useState(false)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)  
  const location = useLocation()
  const [filterDate, setFilterDate] = useState([])
  const tableScrollY = useTableSize(65, 60)

  const sysText803001 = useSystemText(803001)
  const sysText803002 = useSystemText(803002)
  const sysText803003 = useSystemText(803003)
  const sysText803004 = useSystemText(803004)
  const sysText803005 = useSystemText(803005)
  const sysText803006 = useSystemText(803006)
  const sysText803007 = useSystemText(803007)
  const sysText803008 = useSystemText(803008)
  const sysText803009 = useSystemText(803009)
  const sysText803010 = useSystemText(803010)
  const sysText803011 = useSystemText(803011)

  //預設時間為6個月前
  const today = new Date()

  const defaultDate = [
    new Date(today.setHours(0, 0, 0, 0)), // Today's start date (00:00:00)
    new Date(today.setHours(23, 59, 59, 999)) // Today's end date (23:59:59)
  ]  

  const ActiveColumn = (item) => {               
       
    const editHandler = () => {
      setEditData(item)
      setShowEdit(true)
    }

    return (      
      <div className='activeBtn flex flex-row items-center space-x-2 justify-center'>          
        <Button style={{whiteSpace: "nowrap"}} label={sysText803011} severity="warning" raised onClick={editHandler} />                
      </div>
    )
  }      

  const tableColumns = [
    {
      header: sysText803001,
      field: "三方套件名稱",
      style: {minWidth: "120px"},      
    },
    {
      header: sysText803002,
      field: "三方名稱",
      style: {minWidth: "120px"},      
    },
    {
      header: sysText803003,
      field: "總訂單數",
      style: {minWidth: "120px"},  
      sortable: true    
    },
    {
      header: sysText803004,
      field: "成功訂單數",
      style: {minWidth: "120px"},  
      sortable: true     
    },
    {
      header: sysText803005,
      field: "成功支付率",
      style: {minWidth: "120px"},  
      sortable: true     
    },
    {
      header: sysText803006,
      field: "成功訂單金額",
      style: {minWidth: "120px"},   
      sortable: true    
    },
    {
      header: sysText803007,
      field: "成功採購訂單數",
      style: {minWidth: "120px"}, 
      sortable: true      
    },
    {
      header: sysText803008,
      field: "成功採購金額",
      style: {minWidth: "120px"}, 
      sortable: true      
    },
    {
      header: sysText803009,
      field: "採購額度",
      style: {minWidth: "120px"},
      sortable: true       
    },
    {
      header: sysText803010,
      field: "",
      style: {minWidth: "120px"},
      body: ActiveColumn      
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {         

    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      stime: filterDate[0] ? filterDate[0] : defaultDate[0].getTime(),
      etime: filterDate[1] ? filterDate[1] : today.getTime(),
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_111_get_3p_def_statistical(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setThirdPartyResultData(listResponse)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterDate, isRefresh]);      

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showSearchInput={false} setFilterDate={setFilterDate} defaultDate={defaultDate}>
          <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
        </FilterInput>
        <div className=''>
          <DataTable value={thirdPartyResultData} scrollable scrollHeight={tableScrollY}>
            {visibleColumns.map((row,index) =>           
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} sortable={row.sortable}></Column>
            )}              
          </DataTable>    
        </div>

        {/* 編輯三方套件 */}
        {showEdit && <ThirdPartyResultEdit editData={editData} setShowEdit={setShowEdit}/>}
        
      </div>
  );
}

export default ThirdPartyResult