import React, { useState, useEffect, useContext } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
// import ShareRecordCard from '../../../components/share/shareRecord/shareRecordCard';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import ShareRecordWithdraw from '../../../components/share/shareRecord/shareRecordWithdraw';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
// import WithdrawRecord from './withdrawRecord';
import { ToastContext } from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import FilterInput from '../../components/UI/filterInput';
import { web_36_flowrecord_list } from '../../api/api';
import PaginatorUI from '../../components/UI/paginatorUI';
import ApplyWithdraw from '../../components/orderManage/flowRecord/applyWithdraw';
import FlowWithdrawRecord from './flowWithdrawRecord';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const FlowRecord = () => {

  const dateToString = (start_date, end_date) => {      
     
    const startDateString = new Date(start_date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");

    const endDateString = new Date(end_date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");

    const dateString = `${startDateString} ~ ${endDateString}`

    return dateString
  } 

  const [flowRecordData, setFlowRecordData] = useState([])
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [filterNumber, setFilterNumber] = useState("")
  const [filterDate, setFilterDate] = useState([])
  const [displayDate, setDisplayDate] = useState(dateToString(-2209017600000, new Date().getTime()))
  const [filterStatus, setFilterStatus] = useState("")
  const [tabMenu, setTabMenu] = useState('order')
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10); 
  const [totalCount, setTotalCount] = useState(0)
  const [allOrderCount, setAllOrderCount] = useState(0)
  const [allOrderTotal, setAllOrderTotal] = useState(0)
  const [settlementFund, setSettlementFund] = useState(0)
  const [freezeAmount, setFreezeAmount] = useState(0)
  const [showApplyWithdraw, setShowApplyWithdraw] = useState(false)
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")));
  const tableScrollY = useTableSize(55, 45)

  const { showToast } = useContext(ToastContext)
  const location = useLocation()

  const sysText203001 = useSystemText(203001)
  const sysText203002 = useSystemText(203002)
  const sysText203003 = useSystemText(203003)
  const sysText203004 = useSystemText(203004)
  const sysText203005 = useSystemText(203005)
  const sysText203006 = useSystemText(203006)
  const sysText203007 = useSystemText(203007)
  const sysText203008 = useSystemText(203008)
  const sysText203009 = useSystemText(203009)
  const sysText203010 = useSystemText(203010)
  const sysText203011 = useSystemText(203011)
  const sysText203012 = useSystemText(203012)
  const sysText203013 = useSystemText(203013)
  const sysText203014 = useSystemText(203014)
  const sysText203015 = useSystemText(203015)
  const sysText203016 = useSystemText(203016)
  const sysText203017 = useSystemText(203017)
  const sysText203018 = useSystemText(203018)
  const sysText203019 = useSystemText(203019)
  const sysText203020 = useSystemText(203020)
  const sysText203021 = useSystemText(203021)
  const sysText203022 = useSystemText(203022)
  const sysText203023 = useSystemText(203023)
  const sysText203024 = useSystemText(203024)
  const sysText203025 = useSystemText(203025)
  const sysText203026 = useSystemText(203026) 
  const sysText203027 = useSystemText(203027) 
  const sysText203035 = useSystemText(203035) 


  const items = [
    {label: `${sysText203017}`, icon: '', command: () => {setTabMenu('order')}},
    {label: `${sysText203018}`, icon: '',  command: () => {setTabMenu('withdraw')}}    
  ];

  const statusOption = [
    { name: `${sysText203019}`, value: ""},
    { name: `${sysText203020}`, value: '1' },
    { name: `${sysText203021}`, value: '2' },
    { name: `${sysText203022}`, value: '3' },
    { name: `${sysText203023}`, value: '4' },
    { name: `${sysText203024}`, value: '5' },
    { name: `${sysText203025}`, value: '6' },
    { name: `${sysText203026}`, value: '7' }      
  ];

  const OperationColumn = (item) => {
    switch(item.操作類型) {
      case "1":
        return sysText203020
        
      case "2":
        return sysText203021
        
      case "3":
        return sysText203022
        
      case "4":
        return sysText203023
      
      case "5":
        return sysText203024

      case "6":
        return sysText203025
      
      case "7":
        return sysText203026
    }  
  }   

  const tableColumns = [
    {
      header: sysText203005,
      field: "商戶",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203006,
      field: "訂單編號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203007,
      field: "員工帳號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203008,
      field: "操作類型",
      style: {minWidth: "120px"},
      body: OperationColumn
    },
    {
      header: sysText203009,
      field: "交易管道",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203010,
      field: "原金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203011,
      field: "訂單金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203012,
      field: "變動金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203013,
      field: "手續費",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203014,
      field: "變動後金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203015,
      field: "變動日期",
      style: {minWidth: "120px"},
    },
    {
      header: sysText203016,
      field: "備註",
      style: {minWidth: "120px"},
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(permissions) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => { 
          
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),      
      serial: filterNumber,     
      start_date: filterDate?.[0] ? filterDate[0] : -2209017600000,
      end_date: filterDate?.[1] ? filterDate[1] : new Date().getTime(),
      transtype: "",
      operatetype: filterStatus,
      page: page + 1,
      limit: 10
    };    

    const fetchData = async () => {   
      setIsLoading(true)    
      try {        
        const { listResponse, status, statusText } = await web_36_flowrecord_list(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setFlowRecordData(listResponse.data)
          setTotalCount(listResponse.count)
          setAllOrderCount(listResponse.count_data[0].訂單總數)  
          setAllOrderTotal(listResponse.count_data[0].訂單金額加總)
          setSettlementFund(listResponse.am_record)
          setFreezeAmount(listResponse.frozen)
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()  
    if(filterDate[0] && filterDate[1]) {
      setDisplayDate(dateToString(filterDate[0], filterDate[1]))
    } else {
      setDisplayDate(dateToString(-2209017600000, new Date().getTime()))
    }
  }, [filterStatus, filterNumber, filterDate, page]);

  return (
    <div className="shareRecord px-2"> 
      {permissions === 8 && 
      <div className='flex px-4'>
        <Button label={sysText203027} severity='info' onClick={() => {setShowApplyWithdraw(true)}}></Button>
      </div>
      }    
      
      <TabMenu model={items}/>
      
      {(tabMenu === 'order') &&
        <div className=''>
          <div className='flex w-full'>
            <div className='flex flex-col w-full my-2 max-2xl:w-[100%]'>              
              <div className='flex flex-row flex-wrap max-2xl:justify-center w-full'>
               
                <div className='flex min-2xl:ml-auto max-2xl:w-full'>
                  <FilterInput setFilterNumber={setFilterNumber} setFilterDate={setFilterDate} setFirst={setFirst} setPage={setPage} placeholder={sysText203035}>
                    <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect max-2xl:hidden" display='chip'/>
                  </FilterInput>
                </div>
              </div>
              <div className='tableWrap'>
                
                <div className='flex flex-row py-2'>
                  <div className='px-1 flex flex-row w-[70%] text-sm max-2xl:w-full space-x-2 whitespace-nowrap'>                    
                    <div className='flex flex-row min-2xl:p-2 bg-blue-500 rounded-md max-2xl:flex-col'>
                      <p className='border-r min-2xl:mr-4 min-2xl:px-4 max-2xl:w-[100%]  max-2xl:border-none'>{sysText203001}：{allOrderCount}</p>
                      <p className='max-2xl:w-[100%] pr-4'>{sysText203002}：{allOrderTotal}</p>
                    </div>

                    <div className='flex flex-row min-2xl:p-2 bg-yellow-400 rounded-md max-2xl:flex-col'>
                      <p className='border-r min-2xl:mr-4 min-2xl:px-4 max-2xl:w-[100%]  max-2xl:border-none'>{sysText203003}：{settlementFund}</p>
                      <p className='max-2xl:w-[100%] pr-4'>{sysText203004}：{freezeAmount}</p>
                    </div>
                    
                  </div>
                  
                  <div className="max-md:hidden ml-auto">
                    <Dropdown className='custom-dropdown' placeholder="状态" value={filterStatus} onChange={(e) => {setFilterStatus(e.target.value)}} options={statusOption} optionLabel='name'/>                    
                  </div>     
                </div>                        

                <div className=''>
                  <DataTable value={flowRecordData} scrollable scrollHeight={tableScrollY}>                   
                    {visibleColumns.map((row,index) =>             
                      <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} sortable={row.sortable} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
                    )}
                  </DataTable>
                </div>                   

                <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
              </div>
                            
            </div>       
          </div>

          {/* <div className='flex flex-wrap overflow-y-scroll h-[50vh] my-2'>
            {shareRecordData.map((item, index) => 
              <ShareRecordCard key={index} item={item} displayDate={displayDate}/>
            )}
                                 
          </div> */}
        </div>
      }

      {(tabMenu === 'withdraw') &&
       <FlowWithdrawRecord/>
      }

      {/* 申請提領 */}
      {showApplyWithdraw && <ApplyWithdraw setShowApplyWithdraw={setShowApplyWithdraw}/>}
      
    </div>
  );
};

export default FlowRecord;