import React, { useContext, useEffect, useState } from 'react';
import FilterInput from '../../components/UI/filterInput';
import { Button } from 'primereact/button';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../context/ToastContext';
import { web_14_ncc_find_pur_order, web_21_ncc_find_pur_order_card, web_88_pur_order_allotment_or_grab } from '../../api/api';
import Cookies from 'js-cookie';
import PurchaseMateSplit from '../../components/purchaseManage/purchaseMate/purchaseMateSplit';
import Dialog from '../../components/dialog';
import useJson from '../../helper/useJson';
import PurchaseMateNccConfirm from '../../components/purchaseManage/purchaseMate/purchaseMateNccConfirm';
import PurchaseMateNccForce from '../../components/purchaseManage/purchaseMate/purchaseMateNccForce';
import PurchaseMateNccRevoke from '../../components/purchaseManage/purchaseMate/purchaseMateNccRevoke';
import PurchaseMateTransform from '../../components/purchaseManage/purchaseMate/purchaseMateTransform';
import useSystemText from '../../helper/useSystemText';
import PurchaseMateThirdPartyForce from '../../components/purchaseManage/purchaseMate/purchaseMateThirdPartyForce';
import PurchaseMateThirdPartyConfirm from '../../components/purchaseManage/purchaseMate/purchaseMateThirdPartyConfirm';

const PurchaseMateThirdParty = () => {
  const [filterNumber, setFilterNumber] = useState("")
  const [purchaseMateData, setPurchaseMateData] = useState([])
  const [orderCardData, setOrderCardData] = useState([])
  const [selectSplitData, setSelectSplitData] = useState({})
  const [splitDataList, setSplitDataList] = useState([])
  const [showSplit, setShowSplit] = useState(false)
  const [currentSplitId, setCurrentSplitId] = useState("")
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const [showRevoke, setShowRevoke] = useState(false)
  const [selectOrder, setSelectOrder] = useState({})
  const [selectCard, setSelectCard] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmData, setConfrimData] = useState({})
  const [showForce, setShowForce] = useState(false)
  const [cardSort, setCardSort] = useState("")
  const [showGrab, setShowGrab] = useState(false)
  const [grabData, setGrabData] = useState(null)
  const { jsonToObject } = useJson()
  const [info, setInfo] = useState("")

  const sysText309001 = useSystemText(309001)
  const sysText309002 = useSystemText(309002)
  const sysText309003 = useSystemText(309003)
  const sysText309004 = useSystemText(309004)
  const sysText309005 = useSystemText(309005)
  const sysText309006 = useSystemText(309006)
  const sysText309007 = useSystemText(309007)
  const sysText309008 = useSystemText(309008)     
  const sysText309009 = useSystemText(309009)   
  const sysText309010 = useSystemText(309010)     
  const sysText309011 = useSystemText(309011)
  const sysText309012 = useSystemText(309012)     
  const sysText309013 = useSystemText(309013)   
  const sysText309014 = useSystemText(309014)     
  const sysText309015 = useSystemText(309015) 
  const sysText309016 = useSystemText(309016) 
  const sysText309027 = useSystemText(309027)   

  console.log("selectCard: ", selectCard);
  console.log("splitDataList: ", splitDataList);
  console.log("selectSplitData: ", selectSplitData);
  console.log("selectOrder: ", selectOrder);
  console.log("cardSort: ", cardSort);

  const showRevokeHandler = () => {
    setShowRevoke(true)
  }

  const showSpiltHandler = (e, item) => {
    e.target.checked && setShowSplit(true)
    setCurrentSplitId(item.單號)        
  }

  const showConfirmHandler = () => {
    const index = splitDataList.findIndex(row => row.id === selectOrder.單號)    
    setSelectSplitData(splitDataList[index])
    setConfrimData(selectOrder)
    setShowConfirm(true)
  }

  const showForceHandler = () => {
    setShowForce(true)
  }

  const selectOrderHandler = (e, item) => {
    setSelectOrder(item)    
  }

  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectCard(orderCardData);
    } else {
      setSelectCard([]);
    }
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectCard((prevSelected) => [item]);
    } else {
      setSelectCard((prevSelected) =>
        prevSelected.filter((row) => row.卡號 !== item.卡號)
      );
    }
  };
  
  //卡片餘額排序
  const balanceSortHandler = () => {
    if(cardSort === "desc") {
      setCardSort("asc")
    }else {
      setCardSort("desc")
    }
  }

  const showGrabHandler = (item) => {
    setGrabData(item)
    setShowGrab(true)
  }  

  const InfoColumn = (rowData) => {
    return <Button type='button' label={sysText309027} severity='info' text raised onClick={(e) => { 
      try{
        const data = JSON.parse(rowData.資訊)
        setInfo(data)
      }catch (e) {
        setInfo(null)
        showToast("error", "资料格式错误无法显示")
      }
    }}></Button>
  }

  const InfoDialog = ({ info, setInfo }) => {
    if (info === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setInfo(null)}
        header={sysText309010}
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText309010}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {Object.keys(info).map((key) => 
              <p key={key}>{`${key}: ${info[key]}`}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button type='button' style={{ width: "120px" }} label='OK' severity='info' raised onClick={(e) => {setInfo(null)}}></Button>
          </div>
        </div>
      </Dialog>
    );
  }; 

  useEffect(() => {      
    
    const web14_fetchData = async() => {
      setIsLoading(true)
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),    
        select: "",
        grab: "0",
        nccor3p: "1" 
      };
             
      try {
        const { listResponse, status, statusText} = await web_14_ncc_find_pur_order(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {                     
          setPurchaseMateData(listResponse.data)         
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }    
    web14_fetchData()   
    setSelectOrder({})
  }, [isRefresh]);


  useEffect(() => {         
    
    const web21_fetchData = async() => {
      setIsLoading(true)
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),
        select: filterNumber || "",
        nccor3p: "1",
        pur_gateway_id: selectOrder.pur_gateway_id                   
      };
         
      try {
        const { listResponse, status, statusText} = await web_21_ncc_find_pur_order_card(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {                         
          setOrderCardData(listResponse)  
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    } 
    // 選訂單才show卡
    if(Object.keys(selectOrder).length > 0) {
      web21_fetchData()  
    }else {
      setOrderCardData([])
      setSelectCard([])
    }
    setCardSort("")     
     
  }, [selectOrder, filterNumber]);


  //判斷卡片餘額排序按鈕
  useEffect(() => {
    if(selectOrder) {
      const newSelectCard = [...orderCardData]
      if(cardSort === "desc") {
        newSelectCard.sort((a, b) => a.當前餘額 - b.當前餘額);
      }else if(cardSort === "asc"){
        newSelectCard.sort((a, b) => b.當前餘額 - a.當前餘額);
      }
      setOrderCardData(newSelectCard)      
    }
    
  }, [cardSort])
  
  return (
    <div className='purchaseMateWrap p-4'>
      <div className='flex flex-col'>
        <FilterInput showCalendarInput={false} setFilterNumber={setFilterNumber} placeholder="请输入卡号"/>
        <div className='flex flex-row min-md:ml-auto max-md:justify-center space-x-4'>
          {/* 強制送出改成確認送出，三方不需配對 */}
          {/* <Button disabled={selectCard.length === 0 || JSON.stringify(selectOrder) === '{}'} label={sysText309007} severity='warning' raised onClick={showForceHandler}></Button> */}
          <Button disabled={selectCard.length === 0 || JSON.stringify(selectOrder) === '{}'} label={sysText309008} severity='success' raised onClick={showForceHandler}></Button>
          <Button disabled={Object.keys(selectOrder).length === 0} label={sysText309009} severity='danger' raised onClick={showRevokeHandler}></Button>
        </div>
        
        <div className="tableWrap h-[35vh] overflow-scroll">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">               
            <tbody className='space-y-4'>
              {purchaseMateData.map((item, index) => 
                <tr key={item.單號} className="bg-customColor-bgDark text-white flex flex-row">
                  <td className="w-4 p-4 flex items-center justify-center basis-1/12">
                    <div className="flex items-center">
                      <input name="radio" type="radio" checked={selectOrder.單號 === item.單號} onChange={(e) => selectOrderHandler(e, item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500"/>                     
                    </div>
                  </td>                    
                  <td className="px-6 py-4 flex flex-row flex-wrap items-center basis-11/12">
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg whitespace-nowrap'>
                        {sysText309001}
                      </div>
                      <p>{item.單號}</p>                            
                    </div>
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg whitespace-nowrap'>
                        {sysText309002}
                      </div>
                      <p>{item.時間}</p>                            
                    </div>
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg whitespace-nowrap'>
                        {sysText309003}
                      </div>
                      <p>{item.金額}</p>                            
                    </div>
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg whitespace-nowrap'>
                        {sysText309004}
                      </div>
                      <p>{item.商戶號}</p>                            
                    </div>
                  </td>
                  {/* <td className="flex items-center space-x-3 basis-2/12 whitespace-nowrap">
                    <input type="checkbox" onChange={(e) => showSpiltHandler(e, item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500"/>
                    <label htmlFor="checkbox" className="text-white">{sysText309005}</label>                    
                  </td>

                  <td className="flex items-center basis-2/12 whitespace-nowrap">
                    <Button label={sysText309006} severity='danger' raised onClick={() => showGrabHandler(item)}></Button>                    
                  </td> */}
                </tr>              
              )} 
            </tbody>
          </table>
        </div>
        
        <div className="tableWrap overflow-scroll h-[30vh]">          
          <table className="w-full text-sm text-left">
            <thead className="text-xs bg-customColor-bgLightGray text-gray-500 top-0 sticky">
              <tr>
                <th scope="col" className="p-4">
                  {/* <div className="flex items-center">
                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onChange={handleAllCheckboxChange} checked={orderCardData.length > 0 && selectCard.length === orderCardData.length}
                    />
                  </div> */}
                </th>
                <th className='min-w-[120px] px-[0.5rem] py-1'>
                  {sysText309010}
                </th>                
                <th className='min-w-[100px] px-[0.5rem] py-1' onClick={balanceSortHandler}>
                  {sysText309011}
                  {cardSort === "desc" ? 
                    <i className="pi pi-sort-amount-down-alt text-blue-700"></i>
                  : cardSort === "asc" ? 
                    <i className="pi pi-sort-amount-up-alt text-blue-700"></i>
                  : 
                    <i className="pi pi-sort-alt"></i>
                  } 
                </th>
                <th className='min-w-[80px] px-[0.5rem] py-1'>
                  {sysText309012}
                </th>
                {/* <th className='min-w-[80px] px-[0.5rem] py-1'>
                  {"今日收款次數／上限"}
                </th>                 */}
                <th className='min-w-[100px] px-[0.5rem] py-1'>
                  {sysText309013}
                </th>
                <th className='min-w-[80px] px-[0.5rem] py-1'>
                  {sysText309014}
                </th>                
              </tr>
            </thead>
              <tbody className='bg-customColor-bgDark'>
                {orderCardData.map((item) => 
                  <tr key={item.卡号} className="">
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input name="cardCheckbox" type="checkbox" value={selectCard} checked={selectCard.find((row) => row.資訊 === item.資訊)} onChange={(e) => handleCheckboxChange(e, item)} 
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                  </td>                   
                  <td>
                    {InfoColumn(item)}
                  </td>
                  <td>
                    {item.當前餘額}
                  </td>
                  <td>
                    {item['今日收款額/上限(%)']}
                  </td>
                  {/* <td>
                    {item['今日收款次數/上限(%)']}
                  </td> */}
                  <td>
                    {item.信用額度}
                  </td>
                  <td>
                    {item.狀態 === 0 ? "停用" : item.狀態 === 1 ? "启用" : null}
                  </td>
                </tr>          
                )}
                      
              </tbody>
          </table>
        </div>
      </div>
      {/* 撤銷請求 */}
      {showRevoke && <PurchaseMateNccRevoke revokeData={selectOrder} setShowRevoke={setShowRevoke}/>}
      
      {/* 拆分設定 */}
      {showSplit && <PurchaseMateSplit splitDataList={splitDataList} setSplitDataList={setSplitDataList} setShowSplit={setShowSplit} currentSplitId={currentSplitId}/>}

      {/* 確認送出 */}
      {showConfirm && <PurchaseMateThirdPartyConfirm selectCard={selectCard} selectSplitData={selectSplitData} confirmData={confirmData} setShowConfirm={setShowConfirm} />}
      
      {/* 強制送出 */}
      {showForce && <PurchaseMateThirdPartyForce setShowForce={setShowForce} selectOrder={selectOrder} selectCard={selectCard}/> }

      {/* 搶單確認 */}
      {showGrab && <PurchaseMateTransform grabData={grabData} setShowGrab={setShowGrab} />}

      {/* 查看詳情 */}
      {info && <InfoDialog info={info} setInfo={setInfo}/>}
    </div>
  );
};

export default PurchaseMateThirdParty;

// 一般貨款卡配單格式為 [{卡號: 金額}]
// ncc配單格式為 [{id: 金額}] 顯示的卡號資料為資訊裡的 "卡号" 欄位