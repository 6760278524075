import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';

import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import {web_28_create_pur_sub_order} from '../../api/api'
import Cookies from 'js-cookie';
import WithdrawComplete from '../../components/orderManage/flowRecord/withdrawComplete';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';

const FlowWithdrawRecord = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [flowWithdrawRecord , setFlowWithdrawRecord] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  // const [detailData, setDetailData] = useState({})    
  // const [first, setFirst] = useState(0)   
  // const [page, setPage] = useState(0)
  // const [rows, setRows] = useState(10);
  const [showComplete, setShowComplete] = useState(false)
  const [completeData, setCompleteData] = useState({})
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const [permissions] = useState(parseInt(Cookies.get("cookie_permissions")[0]))   
  const tableScrollY = useTableSize(70, 75)

  const sysText203030 = useSystemText(203030)
  const sysText203031 = useSystemText(203031) 
  const sysText203032 = useSystemText(203032)
  const sysText203033 = useSystemText(203033)

  const ActiveColumn = (item) => {        
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''
      switch (item?.狀態) {       
        case "1":
          label = "待确认"
          severity = "warning"
          break
        case "2":
          label = "已确认"
          severity = "success"
          break
        case "3":
          label = "已駁回"
          severity = "danger"
          break        
      }
      return {label, severity}             
    }     

    const {label, severity} = statusBtnCheck()

    const showCompleteHandler = () => {
      setShowComplete(true)
      setCompleteData(item)        
    }

    return (
      <>
        {permissions === 9 ? 
          <div className='activeBtn flex flex-col items-center justify-around'>          
            <Button label={label} severity={severity} disabled={item?.狀態 !== "1"} raised onClick={showCompleteHandler}/>        
          </div>
          :
          <div className='activeBtn flex flex-col items-center justify-around'>          
            <Button label={label} severity={severity} disabled={true} raised onClick={showCompleteHandler}/>        
          </div>
        }
      </>    
      
    )
  }      

  useEffect(() => {    
    const filterData = {          
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")      
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_28_create_pur_sub_order(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setFlowWithdrawRecord(listResponse)                      
        } else {
          showToast("error", "" + status, null, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [location, isRefresh]);      

  return (
      <div className="p-4 recordWrap tableWrap">  
        {/* <FilterInput/>       */}
        <div className=''>
          <DataTable value={flowWithdrawRecord} scrollHeight={tableScrollY} scrollable>
            <Column style={{ minWidth: "150px" }} field="流水編號" sortable header={sysText203030}></Column>
            <Column style={{ minWidth: "120px" }} field="商戶編號" header={sysText203031}></Column>
            <Column style={{ minWidth: "120px" }} field="提領金額" sortable header={sysText203032}></Column>  
            <Column style={{ minWidth: "120px" }} field="備註" header="备注"></Column>              
            <Column style={{ minWidth: "120px" }} field="狀態" header={sysText203033} headerClassName='centered-header' body={ActiveColumn}></Column>
          </DataTable>
          {/* <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> */}
        </div>          

        {/* 確認提領  */}
        {showComplete && <WithdrawComplete setShowComplete={setShowComplete} completeData={completeData}/>}

      </div>
  );
}

export default FlowWithdrawRecord